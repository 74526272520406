import React from "react";
import { Button } from "antd";
import { MdShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ title, info, offer, actual, img, id }) => {
  const navigate = useNavigate();

  return (
    <div
      className="p-[8px] h-[414px] cursor-pointer flex flex-col"
      onClick={() => navigate(`/productdetails/${id}`)}
    >
      <div className="w-full img-box w-[343px] md:w-[230px] lg:w-[296px] h-[260px] lg:h-[243px] overflow-hidden">
        <img
          src={img}
          alt="Product 1"
          className="h-full w-full object-cover pb-2"
        />
      </div>
      <div className="card-info">
        <h4 className="mt-2 md:mt-0 text-[#0D0D0D] font-medium text-[14px] leading-[20px] sm:mb-0 md:mb-2 line-clamp-2 h-[40px]">
          {title}
        </h4>
        <p className="text-[#51525B] text-[10px] ">{info}</p>
        <div className="price my-[8px] flex">
          <p className="text-[#0D0D0D] font-medium text-[16px] leading-[20px] mr-[12px]">
            $ {offer}
          </p>
          <p className="text-[#606060] font-medium text-[16px] leading-[20px] line-through">
            $ {actual}
          </p>
        </div>
      </div>
      <div className="card-btn flex justify-evenly lg:justify-between w-full">
        <Button className="w-1/2 p-0 px-[10px] lg:px-[10px] xl:px-[15px] border border-[#2D66D7] !rounded-lg text-[#0D0D0D] text-[12px] lg:text-[14px] leading-[20px] font-medium h-[35px] xl:h-[40px] lg:h-[35px] mr-2">
          <span>
            <MdShoppingCart />
          </span>
          Add to Cart
        </Button>
        <Button className="w-1/2 bg-[#2D66D7] border border-[#2D66D7] !rounded-lg text-white text-[12px] lg:text-[14px] leading-[20px] font-medium px-[20px] lg:px-[15px] xl:px-[20px] h-[35px] lg:h-[35px] xl:h-[40px] hover:border hover:border-[#2D66D7]">
          Buy Now
        </Button>
      </div>
    </div>
  );
};

export default ProductCard;
