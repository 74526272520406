import React from "react";
import "../Style/cloud.css";
import { FaArrowRightLong, FaMinus, FaPlus } from "react-icons/fa6";
import { Button, Collapse } from "antd";
import { cloudCardItems } from "../../../../global/utils/constants/cloudData";
import { collapsedItems } from "../../../../global/utils/constants/cloudCollapseItem";
import rmc from "../../../../assets/images/rmc.png";

const CloudContent = () => {
  return (
    <div>
      <div className="cloud-bg flex justify-center items-center">
        <p className="bg-text text-center">
          Managing Clouds Without <br />
          Compromising Security
        </p>
      </div>
      {/* cards section */}
      <div className="flex flex-col lg:flex-row my-8 gap-3 custom-padding">
        {cloudCardItems?.map((item, index) => {
          return (
            <div
              key={index}
              className="relative w-full lg:w-1/3 border border-gray-400 p-2 rounded-md"
            >
              <div className="mb-8">
                <img
                  src={item?.image}
                  alt="image"
                  className="w-full h-[256px] object-cover"
                />
              </div>
              <div>
                <h3 className="font-semibold mb-4">{item?.title}</h3>
                <p className="text-[13px] mb-4">{item?.description}</p>
              </div>
              <div className="mb-24">
                <p className="text-[13px] mb-4">{item?.details?.brief}</p>
                <ul class="ml-8 list-disc text-[13px] ">
                  {item?.details?.list?.map((listItem, index) => {
                    return <li key={index}>{listItem}</li>;
                  })}
                </ul>
              </div>
              <div className="absolute bottom-2 left-2">
                <Button
                  type="primary"
                  className="rounded-md font-semibold py-4 px-8 border-blue-700 bg-white text-black"
                >
                  See More
                  <span>
                    <FaArrowRightLong />
                  </span>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      {/* Integration BG section */}
      {/* <img src={integrationBg} alt="image" /> */}
      <div className="cloud-bg-two flex flex-col justify-center items-center text-center text-white custom-padding">
        <p className="text-[24px] md:text-[48px] font-semibold">SICUNET</p>
        <p className="text-[14px] md:text-[16px]">
          All of the RMC software has been thoroughly tested in real-world
          environments, and will continue to be built out to support our
          dealers’ go-to-market strategy. We believe that the RMC software
          solution is a superior alternative to NAT, port forwarding and Virtual
          Private Networking (VPN) technology, enabling easy and secure remote
          access to field-deployed systems. IT managers should also agree, as
          they can implement remote access and connectivity securely without
          mangling the existing network infrastructure and policies.
        </p>
      </div>
      {/* Remote Manager section */}
      <div className="custom-padding mb-8">
        <p className="text-[24px] md:text-[38px] lg:text-[48px] xl:text-[45px] font-semibold my-8 text-center">
          Remote Manager Connection Overview
        </p>
        <div>
          <img src={rmc} alt="image" />
        </div>
      </div>
      {/* Accordion section */}
      <div className="custom-padding mb-12">
        <Collapse
          defaultActiveKey={["1"]}
          ghost
          items={collapsedItems}
          expandIcon={({ isActive }) => (isActive ? <FaMinus /> : <FaPlus />)}
        />
      </div>
    </div>
  );
};

export default CloudContent;
