import React from "react";
export const Whatsapp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FEFFFF"
      d="M12 3.497a9 9 0 0 1 9 9 9 9 0 0 1-9 9 8.956 8.956 0 0 1-4.527-1.22l-4.47 1.22 1.217-4.471A8.955 8.955 0 0 1 3 12.497a9 9 0 0 1 9-9Zm-3.067 4.77-.18.007a.9.9 0 0 0-.335.09 1.17 1.17 0 0 0-.265.205c-.108.102-.169.19-.234.276a2.457 2.457 0 0 0-.51 1.51c.002.441.117.87.297 1.272.368.812.974 1.671 1.773 2.468.193.191.382.384.585.563a8.507 8.507 0 0 0 3.456 1.841l.511.079c.167.009.333-.004.5-.012.263-.014.519-.085.75-.208.118-.06.233-.127.345-.198l.113-.08c.121-.09.196-.155.297-.26.075-.078.138-.169.189-.272.07-.147.14-.427.169-.66.021-.178.015-.275.012-.335-.003-.097-.083-.197-.17-.239l-.524-.235s-.783-.34-1.262-.559a.446.446 0 0 0-.159-.037.431.431 0 0 0-.34.115c-.004-.002-.065.05-.715.838a.315.315 0 0 1-.332.117 1.27 1.27 0 0 1-.171-.06c-.112-.046-.15-.065-.227-.097a5.401 5.401 0 0 1-1.418-.903c-.113-.099-.218-.207-.326-.311a5.674 5.674 0 0 1-.918-1.141l-.053-.086a.9.9 0 0 1-.092-.184c-.034-.133.055-.239.055-.239s.218-.24.32-.369c.099-.126.183-.248.237-.335.106-.171.14-.347.083-.483a73.568 73.568 0 0 0-.78-1.837c-.054-.12-.211-.207-.354-.224a2.7 2.7 0 0 0-.508-.01l.18-.007Z"
    />
  </svg>
);
