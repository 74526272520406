import React, { useState } from "react";
import { Form, Input, Button, Divider } from "antd";
import RequestSuccess from "./RequestSuccess";
import { SicunetIcon } from "../../../global/components/Icons/Sicunet";
import { useNavigate } from "react-router-dom";

const RequestUserForm = () => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const handleFinish = (values) => {
    console.log("value", values);
    if (values.email && values.phone && values.message && values.name) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 4000);
    }
  };
  return (
    <div className="">
      <div className="lg:hidden flex justify-center mb-20">
        <SicunetIcon onClick={() => navigate("/")} />
      </div>
      <div className="flex justify-center bg-[#EFEEE6] px-2 sm:px-6 py-9 max-w-3xl mx-0 lg:mx-auto  rounded-2xl shadow-md ">
        {!showSuccess ? (
          <div>
            <h2 className="text-center sm:text-start text-[24px] text-[#0D0D0D] font-normal mb-4">
              See Sicunet in action
            </h2>
            <Divider className="my-[28px] lg:my-[48px]" />
            <Form
              layout="vertical"
              className="request-form flex flex-col justify-center"
              requiredMark={false}
              onFinish={handleFinish}
            >
              {/* Company Email */}
              <Form.Item
                label="Company email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="example@company.com" />
              </Form.Item>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Name */}
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input placeholder="Mike Shaw" />
                </Form.Item>

                {/* Phone */}
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    { required: true, message: "Please input your phone!" },
                  ]}
                >
                  <Input placeholder="+16105579129" />
                </Form.Item>
              </div>

              {/* Message */}
              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: "Please leave a message!" }]}
                className="mb-[48px]"
              >
                <Input.TextArea rows={4} placeholder="Leave us a message ..." />
              </Form.Item>

              {/* Request Button */}
              <Form.Item className="text-center lg:text-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-[#007AFC] text-white md:w-auto px-6 py-2 rounded-[20px] text-[16px]"
                >
                  Request a Demo
                </Button>
              </Form.Item>
            </Form>

            <Divider className="my-[28px] lg:my-[48px]" />
            <p className="text-sm text-gray-500">
              By clicking "Book a Demo" you agree to our{" "}
              <a href="#" className="text-blue-500">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="text-blue-500">
                Privacy Policy
              </a>
            </p>
          </div>
        ) : (
          <RequestSuccess />
        )}
      </div>
    </div>
  );
};

export default RequestUserForm;
