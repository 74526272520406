import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { routes } from "./routes";
import PageLayout from "./global/components/Layout/PageLayout";
import RequestDemo from "./modules/RequestDemo/Page/RequestDemo";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {routes.map((route, id) => (
            <Route
              key={id}
              path={route.path}
              element={<PageLayout children={route.element} />}
            />
          ))}
          <Route path="/requestdemo" element={<RequestDemo />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
