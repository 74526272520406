import React from "react";

const HdPictures = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38112 5.33301H23.6192C24.4275 5.33301 25.2027 5.6541 25.7742 6.22563C26.3457 6.79717 26.6668 7.57235 26.6668 8.38063V23.6187C26.6668 24.427 26.3457 25.2022 25.7742 25.7737C25.2027 26.3453 24.4275 26.6663 23.6192 26.6663H8.38112C7.57284 26.6663 6.79766 26.3453 6.22612 25.7737C5.65458 25.2022 5.3335 24.427 5.3335 23.6187V8.38063C5.3335 7.57235 5.65458 6.79717 6.22612 6.22563C6.79766 5.6541 7.57284 5.33301 8.38112 5.33301Z"
        stroke="#6880CF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6668 20.5712L22.0954 15.9998L17.524 20.5483M23.6192 26.6664L9.90492 12.9521L5.3335 17.5236"
        stroke="#6880CF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3334 12.1902C22.175 12.1902 22.8572 11.508 22.8572 10.6664C22.8572 9.82481 22.175 9.14258 21.3334 9.14258C20.4918 9.14258 19.8096 9.82481 19.8096 10.6664C19.8096 11.508 20.4918 12.1902 21.3334 12.1902Z"
        fill="#6880CF"
      />
    </svg>
  );
};

export default HdPictures;
