import React from "react";
export const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#FEFFFF"
      d="m14.56 5.701-.06-.24a3.331 3.331 0 0 0-.313-.666 3.166 3.166 0 0 0-1.213-1.16l-3.5-1.894a3.2 3.2 0 0 0-3.007 0l-3.5 1.894c-.502.27-.92.67-1.214 1.16-.13.208-.233.432-.306.666v.094a3.066 3.066 0 0 0-.114.82v5.08A3.173 3.173 0 0 0 4.5 14.62h7a3.167 3.167 0 0 0 3.167-3.166V6.368a3.201 3.201 0 0 0-.107-.667ZM9.053 7.935a2.2 2.2 0 0 1-2.166 0l-4.353-2.52c.02-.051.044-.1.073-.147.201-.335.489-.609.833-.793l3.5-1.894a2.16 2.16 0 0 1 2.06 0l3.5 1.894c.412.222.74.573.934 1l-4.38 2.46Z"
    />
  </svg>
);
