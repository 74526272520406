import React from "react";
import SalesInfo from "./SalesInfo";
import ContactForm from "./ContactForm";

const ContactWrapper = () => {
  return (
    <div className="px-8 md:px-12 lg:px-24 xl:px-40 2xl:px-[344px] flex flex-col-reverse lg:grid lg:grid-cols-2 mt-[30px] gap-[24px] mb-[50px]">
      <SalesInfo />
      <ContactForm />
    </div>
  );
};

export default ContactWrapper;
