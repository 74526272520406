import React from "react";

const LifeTime = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.417 15.7132C23.368 10.7032 22.301 5.73516 19.463 3.99416C16.781 2.34916 14.44 3.01216 13.034 4.06816L12 4.84216M19.417 15.7132C18.469 16.9162 17.232 18.1202 15.66 19.2832C14.115 20.4282 13.342 21.0002 12 21.0002C10.658 21.0002 9.88598 20.4282 8.33998 19.2832C0.221981 13.2752 1.01798 6.15316 4.53698 3.99416C7.21898 2.34916 9.55998 3.01216 10.966 4.06816L12 4.84216M19.417 15.7132L13.892 9.44516C13.7823 9.32114 13.6322 9.24002 13.4683 9.21622C13.3044 9.19242 13.1374 9.22748 12.997 9.31516L10.811 10.6812C10.3823 10.9524 9.86534 11.0473 9.36827 10.946C8.87121 10.8448 8.43255 10.5553 8.14408 10.138C7.85562 9.72074 7.73969 9.20808 7.82056 8.7073C7.90143 8.20652 8.17283 7.75641 8.57798 7.45116L12 4.84216"
        stroke="#0D0D0D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LifeTime;
