import React from "react";
import user from "../../../assets/requestDemo/requser.png";
import { SicunetIcon } from "../../../global/components/Icons/Sicunet";
import { useNavigate } from "react-router-dom";
const RequestUser = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mb-[280px] hidden lg:block">
        <SicunetIcon onClick={() => navigate("/")} />
      </div>

      <div className="flex flex-col md:flex-row md:items-start items-center text-center sm:text-start mt-[20px] lg:mt-0">
        <div className="user-img-box min-w-[120px] h-[120px] mt-[10px] mb-4 sm:mb-0">
          <img
            src={user}
            alt="Ceo of Sicunet inc"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="">
          <p className="text-[24px] md:text-[32px] font-medium text-[#FEFFFF] md:ml-[32px] mb-[32px]">
            “Intuitive User{" "}
            <span className="hidden lg:inline">
              <br />
            </span>
            Interface is easy to{" "}
            <span className="hidden lg:inline">
              <br />
            </span>
            navigate and{" "}
            <span className="hidden lg:inline">
              <br />
            </span>
            customizable for your{" "}
            <span className="hidden lg:inline">
              <br />
            </span>
            clients needs”
          </p>

          <p className="text-[#AFAFB4] text-[16px] md:ml-[32px]">
            IKE HUH, CEO of Sicunet
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequestUser;
