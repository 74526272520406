import React from "react";
export const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="#FEFFFF" d="M5.8 7.8h8.4L10 12 5.8 7.8Z" />
  </svg>
);
