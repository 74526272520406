import React from "react";

const Cart = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4062 11.4062C10.6771 11.1354 10.9896 11 11.3438 11C11.6979 11 12 11.1354 12.25 11.4062C12.5208 11.6771 12.6562 11.9896 12.6562 12.3438C12.6562 12.6979 12.5208 13.0104 12.25 13.2812C12 13.5312 11.6979 13.6562 11.3438 13.6562C10.9896 13.6562 10.6771 13.5312 10.4062 13.2812C10.1354 13.0104 10 12.6979 10 12.3438C10 11.9896 10.1354 11.6771 10.4062 11.4062ZM0.65625 0.34375H2.84375L3.46875 1.65625H13.3438C13.5312 1.65625 13.6875 1.72917 13.8125 1.875C13.9375 2 14 2.15625 14 2.34375C14 2.36458 13.9688 2.46875 13.9062 2.65625L11.5312 6.96875C11.2812 7.42708 10.8958 7.65625 10.375 7.65625H5.40625L4.8125 8.75L4.78125 8.84375C4.78125 8.94792 4.83333 9 4.9375 9H12.6562V10.3438H4.65625C4.30208 10.3438 3.98958 10.2083 3.71875 9.9375C3.46875 9.66667 3.34375 9.35417 3.34375 9C3.34375 8.79167 3.39583 8.58333 3.5 8.375L4.40625 6.71875L2 1.65625H0.65625V0.34375ZM3.71875 11.4062C3.98958 11.1354 4.30208 11 4.65625 11C5.01042 11 5.32292 11.1354 5.59375 11.4062C5.86458 11.6771 6 11.9896 6 12.3438C6 12.6979 5.86458 13.0104 5.59375 13.2812C5.32292 13.5312 5.01042 13.6562 4.65625 13.6562C4.30208 13.6562 3.98958 13.5312 3.71875 13.2812C3.46875 13.0104 3.34375 12.6979 3.34375 12.3438C3.34375 11.9896 3.46875 11.6771 3.71875 11.4062Z"
        fill="#3A3A41"
      />
    </svg>
  );
};

export default Cart;
