import React from "react";
import { SicunetIcon } from "../Icons/Sicunet";
import CustomInput from "../inputWidgets/CustomInput";
import { Button, Input } from "antd";
import "./footer.css";
import { Facebook } from "../Icons/Facebook";
import { Whatsapp } from "../Icons/Whatsapp";
import { Youtube } from "../Icons/Youtube";
import { Linkedin } from "../Icons/Linkedin";
import { EmailIcon } from "../Icons/EmailIcon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-[#0d0d0d] pt-16 pb-8 custom-padding">
      <div className="">
        <SicunetIcon />
      </div>
      <div className="text-white pt-12 text-center">
        <p className="text-[20px] md:text-[24px]">
          Want to know more about our products?
        </p>
        <p className="pt-2 text-xs">subscribe to our newsletter</p>
      </div>
      <div className="w-full flex justify-center items-center pt-8 sm:pt-12">
        {/* <CustomInput placeholder=" Your email here" className="rounded-full" /> */}
        <Input
          prefix={<EmailIcon />}
          placeholder="Your email here"
          className="rounded-full bg-neutral-500/80 border-none email-text"
        />
        <Button
          type="primary"
          className="rounded-full sm:mt-0 ml-2 px-4 py-2 text-base sm:w-[112px] h-[40px]"
        >
          Subscribe
        </Button>
      </div>

      <div className="w-full text-white flex flex-col sm:flex-row justify-between pt-8 sm:pt-16 pb-6 text-xs ">
        <div className="flex justify-center">
          <ul className="flex text-center">
            <li className="border-b cursor-pointer mb-6 sm:mb-0 text-[14px] sm:text[16px]">
              <Link to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>
        <ul className="flex justify-between space-x-2 sm:space-x-6">
          <li className="border-b cursor-pointer text-[14px] sm:text[16px]">
            Terms & Conditions
          </li>
          <li className="border-b cursor-pointer text-[14px] sm:text[16px]">
            Privacy Policy
          </li>
          <li className="border-b cursor-pointer text-[14px] sm:text[16px]">
            Cookies
          </li>
        </ul>
      </div>

      <hr className="bg-white h-[1px] w-full" />
      <div className="hidden w-full sm:flex justify-between text-white pt-6 text-xs">
        <div>
          <p>Sicunet © 2024 All rights reserved </p>
        </div>
        <div className="flex space-x-2">
          <Facebook />
          <Whatsapp />
          <Youtube />
          <Linkedin />
        </div>
      </div>
      <div className="sm:hidden w-full flex flex-col items-center justify-center text-white pt-6 text-xs">
        <div className="flex space-x-2 pb-4">
          <Facebook />
          <Whatsapp />
          <Youtube />
          <Linkedin />
        </div>
        <div>
          <p>Sicunet © 2024 All rights reserved </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
