import React from "react";
import Filter from "./Filter";
import ProductCardWrapper from "./ProductCardWrapper";
import { Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ProductsAndFilter = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 custom-padding">
      <div className="col-span-1">
        <Filter />
      </div>
      <div className="col-span-1 lg:col-span-3">
        <ProductCardWrapper />
        <Pagination
          className="mt-4 mb-16"
          align="end"
          defaultCurrent={1}
          total={20}
          prevIcon={<LeftOutlined />}
          nextIcon={<RightOutlined />}
        />
      </div>
    </div>
  );
};

export default ProductsAndFilter;
