import React from "react";
import SuccessIcon from "../../../global/components/Icons/SuccessIcon";
import { Button } from "antd";

const RequestSuccess = () => {
  return (
    <div className="flex flex-col justify-center items-center fade-in">
      <SuccessIcon />
      <p className="text-[#02BF6C] text-[16px] font-bold leading-[24px] my-[24px]">
        Your demo request has been submitted successfully
      </p>
      <div className="text-[12px] font-medium px-[24px] py-[12px] rounded-[16px] bg-[#D7D7D7] text-[#6F6E77] mb-[40px]">
        A representative from Sicunet will contact you soon
      </div>
      <Button className="bg-[#007AFC] p-[16px] rounded-[20px] text-white text-[16px]">
        Back To Home
      </Button>
    </div>
  );
};

export default RequestSuccess;
