import React from "react";

const ProductBanner = () => {
  return (
    <div className="flex justify-center items-center text-white z-50 product-banner">
      <h1 className="text-white text-[24px] lg:text-[48px] font-semibold leading-[97px]">
        Our Products
      </h1>
    </div>
  );
};

export default ProductBanner;
