import React from "react";
import "../Style/e3.css";
import secondBg from "../../../../assets/images/e3SecondBg.png";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";

const E3Content = () => {
  return (
    <>
      <FeatureTopBar />

      <div className="custom-padding mt-16">
        <p className="text-[24px] font-bold mb-4">
          OVERVIEW OF NEPTUNE ACCESS BENEFITS WHEN COMPARED TO E3
        </p>
        <p className="primary-text">
          1. Product technology platform and software modernized
        </p>
        <p className="secondary-text">i. Hardware –</p>
        <li className="list-disc">
          Faster processing – Quad Core processor – 12X faster
        </li>
        <li className="list-disc">
          TCIP Connection is Gigabit or up to 10X faster Bandwidth for a
          responsive UI
        </li>
        <li className="list-disc">
          True – One door, two door and 4 door configurations all UL 294
        </li>
        <li className="list-disc">
          IEC cord on multi door panels with option for integrated 12 and or 24
          volt lock power!
        </li>
        <li className="list-disc">
          Modern hardware and will not need to change for many years.
        </li>
        <p className="secondary-text">
          ii. Software UX (Enhancement driven by customer feedback)
        </p>
        <li className="list-disc">
          HTML5 UI – Security and Scaling to devices
        </li>
        <li className="list-disc">
          Simplified UI – Removed set up Icons making it “Easier to use for the
          end user!”
        </li>
        <li className="list-disc">
          Secure – Security layers are updated – Compare PEN testing
        </li>

        <p className="paragraph-text">a.AES 265 – End to end encryption </p>
        <p className="paragraph-text">b.Over 18 security enhancements </p>
        <p className="paragraph-text">
          c.License on Demand Upgrade allows the installer on the fly to change
          the product configuration and capabilities. Virtual upgrade process.
          Reducing the number of sku’s for install and service. Do not need MAC
          address to upgrade – just your email.{" "}
        </p>
        <p className="secondary-text">iii. Product Knowledge</p>
        <li className="list-disc">
          Your already trained on 90% of this product. You not ask for a lower
          cost opportunity to leverage your existing knowledge to transition
          your product portfolio to a faster, more feature products help
          rejuvenate your access control offering!
        </li>
        <li className="list-disc">
          Transition extends the life of your legacy offering and present an
          opportunity to promote services and maintenance agreements.
        </li>
        <li className="list-disc">
          Faster processing – Quad Core processor – 12X faster
        </li>
        <li className="list-disc">
          TCIP Connection is Gigabit or up to 10X faster Bandwidth for a
          responsive UI
        </li>
        <li className="list-disc">
          True – One door, two door and 4 door configurations all UL 294
        </li>
        <p className="primary-text">
          2. FEATURES YOUR CUSTOMERS HAVE BEEN ASKING FOR AND YOU NEED TO BETTER
          PROVIDE SERVICES (RMR WHILE LOWERING INSTALL / SUPPORT COSTS.)
        </p>
        <p className="secondary-text">
          i. Two primary models (Simplified ordering process)
        </p>
        <li className="list-disc">
          Advantage – standard on all hardware – value leading feature set with
          core access features – Enabled to support 32 doors – Common hardware
          that is field selectable for Server, Expansion modules and or Elevator
          – on the fly
        </li>
        <li className="list-disc">
          Professional – software key only this offers enhanced features
          required for larger more demanding applications – Enabled to support
          128 doors
        </li>
        <p className="secondary-text">
          ii. Mobile apps with capability for local network connection and or
          via secure cloud connection
        </p>
        <li className="list-disc">
          Lock and unlock doors, view events and live and tagged video, user
          management and Threat levels. Secure management with on fees – Android
          and IOS (Neptune Mobile)
        </li>
        <li className="list-disc">
          License on Demand Upgrade allows the installer on the fly to change
          the product configuration and capabilities. Virtual upgrade process.
          Reducing the number of sku’s for install and service. Do not need MAC
          address to upgrade – just your email.
        </li>
        <p className="secondary-text">iii. Remote Manager</p>
        <li className="list-disc">
          In today’s environment the ability to securely access system from
          anywhere is a necessity. Hybrid management provide the accessibility
          client requires while having all the benefits of privacy and edge
          management enhances physical security.
        </li>
        <p className="secondary-text">
          iv. Integrated Video – Live and Tagged events – built in NVR option
          can store 5K 60 second video clips of defined events.
        </p>
        <li className="list-disc">
          Icon tied to events for one click video retrieval and verification
        </li>
        <li className="list-disc">
          The market is asking for integration of access and video – low cost
          option and market differentiator. Market data to supports integration
          trend and size of market is 3X over non-integrated platforms.
          Scalability – We can scale to 256 panels – 512 doors, Take the system
          database back up and apply to upgrade server – ready to go.
        </li>
        <p className="secondary-text">
          v. Many bug fixes and feature enhancements – one example – in an
          “access denied” event we further define why denied, if it is a un know
          card or format we capture the data. In the log click the color-coded
          hyper link to create or add – do not need to scan again.
        </p>
        <p className="primary-text">3. NO COST UPGRADES AND UPDATES</p>
        <p className="secondary-text">
          i. Two primary models (Simplified ordering process)
        </p>
        <li className="list-disc">
          All platforms – system maintenance upgrades at no charge
        </li>
        <li className="list-disc">
          Sicunet offers the ability to convert legacy hardware to support
          Neptune software. You can add Sicunet hardware or buy more e3
          (Manufacture will require upgrade)
        </li>
        <li className="list-disc">
          All hardware is backwards compatible to new with upgrade.
        </li>
        <p className="primary-text">4. PRODUCT PRICING IS LOWER COST!</p>
        <li className="list-disc">Simple as our product is lower cost.</li>
        <li className="list-disc">Fewer SKU’s to manage</li>
        <li className="list-disc">5 Year Warranty</li>
        <p className="primary-text">5. ROADMAP – WE HAVE ONE!</p>
        <li className="list-disc">
          Wireless locksets, Additional Cloud Services to enhance the ROI and
          lower TCO of installations
        </li>
        <li className="list-disc">
          Many other integrations and feature enhancements
        </li>
        <li className="list-disc">
          We would like to work with you to learn your requirements.
        </li>
        <p className="primary-text">6. SICUNET INC.</p>
        <p className="paragraph-text">
          i. Two primary models (Simplified ordering process)
        </p>
        <li className="list-disc">
          10 Plus Year designing and manufacturing access control and video
          products
        </li>
        <li className="list-disc">
          OEM to major North American Security Industry suppliers
        </li>
        <li className="list-disc">Multiple OEM’s under contract today.</li>
        <li className="list-disc">
          Dedicated team to develop access and video technologies, no other
          conflicts with development
        </li>
        <li className="list-disc">We value your business!</li>
        <p className="primary-text">e3 Upgrade Overview</p>
        <p className="secondary-text">Who is Sicunet?</p>
        <p className="paragraph-text">
          Sicunet has been in business for over 10 years designing and
          manufacturing access control and video products. Today we have
          contracts with several OEM companies and as their partner, they work
          with us to support and evolve the technology. We were the original
          designers of the e3 platform, and we sold the hardware and software to
          the company in Carlsbad, CA. Until recently, That Company had an
          exclusivity agreement in the North American market and during that
          time Sicunet continued to sell the product in the rest of the world.
          Now, this market exclusivity has ended allowing us to compete. Our
          Neptune platform has similar roots but is 100% redesigned with an
          updated user interface, enhanced security, mobile app secure off-site
          connectivity, video integration and many enhancements that you have
          been asking for.
        </p>
        <p className="secondary-text">
          How is it that Neptune can takeover e3 products?
        </p>
        <p className="paragraph-text">
          Having a deep understanding of how the e3 product operates we have
          built a utility that replaces the outdated software and loads a
          version of our current code that can operate on the e3 hardware. This
          then enables the bulk of the features presently available in our
          Neptune platform.
        </p>
        <p className="secondary-text">
          Can Sicunet hardware interconnect with e3?
        </p>
        <p className="paragraph-text">
          Neptune products cannot interconnect with e3 without the software
          conversion being applied to the e3 controllers. Once applied, Sicunet
          panels or one of our OEM customers panels can interconnect with e3
          controllers. Note that after applying the update, you cannot revert
          back to e3. You can still buy a new e3, apply the conversion and add
          it into the system or purchase one of ours or our partners controllers
          to expand the system. I suspect you will choose our controllers as we
          are less expensive, easier to install and have a 5-year warranty.
        </p>
        <p className="secondary-text">What is the conversion process?</p>
        <p className="paragraph-text">
          You send us your system server’s MAC address and model. We will send
          you a link to a video and conversion utility. All you need to do is
          follow the video and run the utility. The process takes about 5
          minutes per controller. If you have multiple units (server and
          clients) the utility can update them all with one click. The existing
          e3 database is transferred from the legacy e3 to the Neptune system,
          so you don’t have to reenter any data.
        </p>
        <p className="secondary-text">
          Once updates are applied who will provide technical support and future
          updates.
        </p>
        <p className="paragraph-text">
          In most cases we will sell products through one of our OEM partners.
          Each has been trained and ultimately has us to support high level
          issues. If an issue escalates, we have dedicated engineering staff
          that will rapidly respond. Sicunet also has call in support for select
          customers who choose to work directly with us.
        </p>
        <p className="secondary-text">Are there on-going fees for updates?</p>
        <p className="paragraph-text">
          As you are accustomed, there are currently no ongoing fees for
          software updates or services provided. Buy it once and away you go. No
          monthly fees for Apps or updates!
        </p>
        <p className="secondary-text">How to you order the upgrade?</p>
        <p className="paragraph-text">
          Yes, we are selling the upgrade to provide dealers to easily convert
          and support their field deployed systems. Many dealers are using the
          conversion tool as an opportunity to go back to customers to add new
          features, security enhancements, service contract and remote
          management while extending the life to the client’s investment.
        </p>
        <div className="flex justify-center my-12">
          <img src={secondBg} alt="image" />
        </div>
        <p className="secondary-text">
          Does Sicunet offer systems and hardware?
        </p>
        <p className="paragraph-text mb-12">
          Sicunet and our OEM partners offer a breadth of panels. 1 Door, 2
          Door, 4 Door with or without lock power supplies. This may vary by
          OEM. What is different is our system is each panel has the ability to
          be configured as a server, expansion panel and elevator module during
          installation. This feature allows for the greatest flexibility of
          inventory. All controllers ship with the Advantage features (Similar
          to Essentials) – value leading features for basic applications. These
          lower cost panels offer a 5-year warranty and are licensed for up to
          32 doors. YES, our basic model can control more than 4 doors! Our
          unique licensing can be used to add software keys that unlock even
          greater features. Professional is similar to Elite but offers 32 doors
          and Professional Plus adds Pro features plus 128 doors, hard and soft
          anti-pass back, custom reports mustering and more. All versions have
          App management and capability to be managed remotely. We also have two
          additional server products that scale to 512 doors and 256 clients. To
          learn more let us know and we can send the specifications – Our
          hardware is significantly faster – 20X processing with a Gigabit
          connection laid out in a way that makes it even easier to install. The
          following chart shows the primary features for each model.
        </p>
      </div>
    </>
  );
};

export default E3Content;
