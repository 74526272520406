import React from "react";
import UserMannual from "../../../global/components/Icons/UserMannual";
import QuickStart from "../../../global/components/Icons/QuickStart";
import Flyer from "../../../global/components/Icons/Flyer";
import IpFinder from "../../../global/components/Icons/IpFinder";
import ApplicationNote from "../../../global/components/Icons/ApplicationNote";
import HdPictures from "../../../global/components/Icons/HdPictures";
import NetWorkGuide from "../../../global/components/Icons/NetWorkGuide";

const AlwaysHelp = () => {
  return (
    <div className="px-[16px] py-[24px] border border-[#EEEEEF] shadow-sm rounded-lg w-full">
      <h4 className="text-[#121212] text-[16px] font-semibold">
        We are always here to help
      </h4>
      <p className="text-[#51525B] text-[13px] mb-[24px]">
        Here are Some Resources Which will help you get started 
      </p>

      <div className=" grid grid-cols-2 md:grid-cols-3">
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <UserMannual />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            User Mannual
          </p>
        </div>

        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <IpFinder />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            IP Finder
          </p>
        </div>
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <ApplicationNote />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            Application Note
          </p>
        </div>
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <Flyer />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">Flyer</p>
        </div>
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <HdPictures />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            HD Pictures
          </p>
        </div>
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <NetWorkGuide />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            Network Guide
          </p>
        </div>
        <div className="help-box mb-6 flex justify-center flex-col items-center">
          <QuickStart />
          <p className="text-[#51525B] text-[11px] whitespace-nowrap">
            Quick Start Guide
          </p>
        </div>
      </div>
    </div>
  );
};

export default AlwaysHelp;
