import About from "./modules/About/Page/About";
import ContactUs from "./modules/ContactUs/Page/ContactUs";
import Home from "./modules/Home/Page/Home";
import Cloud from "./modules/KeyFeatures/Cloud/Page/Cloud";
import PrivacyPolicy from "./modules/PrivacyPolicy/Page/PrivacyPolicy";
import Product from "./modules/Product/Page/Product";
import ProductDetails from "./modules/ProductDetails/Page/ProductDetails";
import ReleaseNote from "./modules/ReleaseNote/Page/ReleaseNote";
import RequestDemo from "./modules/RequestDemo/Page/RequestDemo";
import E3TakeOver from "./modules/KeyFeatures/E3TakeOver/Page/E3TakeOver";
import Lockset from "./modules/KeyFeatures/Lockset/Page/Lockset";
import MobileLink from "./modules/KeyFeatures/MobileLink/Page/MobileLink";
import Nvr from "./modules/KeyFeatures/Nvr/Page/Nvr";
import SmartReport from "./modules/KeyFeatures/SmartReport/Page/SmartReport";
import VideoRecording from "./modules/KeyFeatures/VideoRecording/Page/VideoRecording";
import VideoTagging from "./modules/KeyFeatures/VideoTagging/Page/VideoTagging";
import WarrantyPolicy from "./modules/WarrantyPolicy/Page/WarrantyPolicy";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/product", element: <Product /> },
  { path: "/productdetails/:id", element: <ProductDetails /> },
  // Key Feature Routes
  { path: "/company", element: <About /> },
  { path: "/cloud", element: <Cloud /> },
  { path: "/e3-takeover", element: <E3TakeOver /> },
  { path: "/mobile-link", element: <MobileLink /> },
  { path: "/nvr", element: <Nvr /> },
  { path: "/schlage-lockset", element: <Lockset /> },
  { path: "/smart-report", element: <SmartReport /> },
  { path: "/video-recording", element: <VideoRecording /> },
  { path: "/video-tagging", element: <VideoTagging /> },
  { path: "/warrantypolicy", element: <WarrantyPolicy /> },
  { path: "/privacypolicy", element: <PrivacyPolicy /> },
  { path: "/releasenote", element: <ReleaseNote /> },
  { path: "/contactus", element: <ContactUs /> },
];
