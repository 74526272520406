import React from "react";
export const Youtube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FEFFFF"
      fillRule="evenodd"
      d="M12 4.497c.855 0 1.732.022 2.582.058l1.004.048.961.057.9.061.822.064a3.8 3.8 0 0 1 3.494 3.423l.04.425.075.91c.07.943.122 1.971.122 2.954 0 .983-.052 2.011-.122 2.954l-.075.91-.04.425a3.8 3.8 0 0 1-3.495 3.423l-.82.063-.9.062-.962.057-1.004.048c-.86.037-1.721.057-2.582.058a62.096 62.096 0 0 1-2.582-.058l-1.004-.048-.961-.057-.9-.062-.822-.063a3.8 3.8 0 0 1-3.494-3.423l-.04-.425-.075-.91A41 41 0 0 1 2 12.497c0-.983.052-2.01.122-2.954l.075-.91.04-.425A3.8 3.8 0 0 1 5.73 4.785l.821-.064.9-.06.962-.058 1.004-.048c.86-.037 1.722-.057 2.583-.058Zm-2 5.575v4.85c0 .462.5.75.9.52l4.2-2.425a.6.6 0 0 0 0-1.04l-4.2-2.424a.6.6 0 0 0-.9.52Z"
      clipRule="evenodd"
    />
  </svg>
);
