import { Tabs } from "antd";
import React from "react";
import Product from "../../../global/components/Icons/Product";
import Features from "../../../global/components/Icons/Features";
import Setting from "../../../global/components/Icons/Setting";
import interk from "../../../assets/product/intertek.png";
import browser from "../../../assets/product/browser.png";
import cloud from "../../../assets/product/cloud-outline.png";
import doller from "../../../assets/product/doller.png";
import galaSecure from "../../../assets/product/gala_secure.png";
import multipleUser from "../../../assets/product/multipleuser.png";
import ndaa from "../../../assets/product/ndaa.png";
import solar from "../../../assets/product/solar.png";
const ProductTab = () => {
  const tabItems = [
    {
      key: "1",
      label: "Product",
      icon: <Product />, // Apple icon
      children: (
        <div>
          <p className="text-[#51525B] font-[Poppins] text-[13px] text-justify mb-[10px]">
            At the heart of Sicunet's access control platform is an embedded
            browser managed physical access control system (PACS) eco system
            that can scale from one to 512 doors, with up to 100,000 users with
            one or many sites On- premise and Cloud management. Our architecture
            is better than our competitors, as each panel contains a full system
            operating system with a web server. Any piece of hardware can be
            individually managed as a separate system or configured so that they
            talk with each other as one large system. Each panel has the ability
            to morph into any function within the system. Every panel has a full
            copy of the database for database reduncency. The bulk of our
            competitors have specific software and hardware for this capability.
            This design ensure your system should it need service that it is it
            can be serviced faster ans at a lower cost than our competitors.
          </p>
          <p className="text-[#51525B] font-[Poppins] text-[13px] text-justify mb-[10px]">
            Browser Managed provides the greatest flexibility for programming.
            Use any smart device with a browser and have concurrent
            administrators with out having to load or manage other software.
            Eliminate the requirement to manage software and its compatibility!
          </p>
          <p className="text-[#51525B] font-[Poppins] text-[13px] text-justify mb-[10px]">
            Our "Hybrid" design allows the system to be managed on premise or
            via the cloud with the same browser. Our key benefits are you own
            your data, you own the software and will function with no compromise
            of features if the cloud is not functioning.
          </p>
          <p className="text-[#51525B] font-[Poppins] text-[13px] text-justify mb-[10px]">
            Our system includes a mobile application. This application provides
            a basic functions of managing your system, looking and unlocking of
            your doors as well as viewing events with video.
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: "Features",
      icon: <Features />,
      children: (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-[30px] lg:gap-[100px] md:gap-[50px] my-[30px]">
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={interk}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              UL 294 Listed
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={browser}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Browser Managed
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={ndaa}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              NDAA Compliant
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={solar}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Scalability
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={cloud}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] text-center font-medium">
              Cloud Management
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={galaSecure}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-[16px] font-medium">
              Secure
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={doller}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-center text-[16px] font-medium">
              Low total cost of ownership
            </p>
          </div>
          <div className="features-box flex justify-center items-center flex-col">
            <img
              src={multipleUser}
              alt="Intertek"
              className="h-[40px] w-[40px] sm:h-[80px] sm:w-[80px]"
            />
            <p className="text-[#404040] font-[Poppins] text-center text-[16px] font-medium">
              Multiple User Privileges
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Specification",
      icon: <Setting />,
      children: (
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div class="specification-container">
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Model
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                P-500
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Technology
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Proximity
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Frequency
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                125 kHz
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Mounting
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Metal or plastic US single-gang wall box, as well as flat
                surfaces
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Dimensions
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                1.7" W * 3.2" H * 0.7" D<br />
                (43 mm * 81 mm * 18 mm)
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                OEM Label Area Dimensions
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                0.9" W 0.35" H (22.86 mm 8.89 mm)
                <br />
                with corner radius of 0.06" (1.5 mm)
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Weight
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                3 oz (85 g)
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Certifications
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                FCC, ICC, CE, C-Tick, UL Standard 2941
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                IP Code2
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                IP67
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Voltage3
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                +5 - 16 VDC
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Current Draw
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                35 mA typical, 75 mA peak @ 12 VDC
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Read Range4
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Up to 8 inches (202 mm)
              </span>
            </div>
          </div>

          <div class="specification-container">
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Cabling5
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                24 AWG minimum, multiconductor stranded with an overall foil
                shield
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Interface
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Wiegand (26-bit and custom formats), ABA Track II magnetic
                stripe (clock and data) or OSDP6
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Operating Temperature
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                –40° F to 149° F (–40° C to +65° C)
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Color Options
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Black and a white snap-on cover included standard
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Audio Tone
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Beeper included standard
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Indoor & Outdoor Installation
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Electronics sealed in weather- and tamper-resistant epoxy
                potting
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Warranty
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Limited lifetime warranty
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                LED
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                Four-state standard (red, green, amber, and off)
              </span>
            </div>
            <div class="specification-row">
              <span class="label text-[#929292] text-[16px] font-medium">
                Technologies Supported
              </span>
              <span class="value text-[#414141] text-[16px] font-medium lg:font-semibold text-left">
                P-300: Pyramid Series Proximity® Cards and Tags6 <br /> P-300-H:
                Pyramid + certain HID® 125-kHz Proximity protocols7 <br />
                P-300-A: Pyramid + certain AWID® 125-kHz Proximity protocols8{" "}
                <br /> P-300-H-A: Pyramid + certain HID® 125-kHz Proximity
                protocols + certain AWID® 125-kHz Proximity protocol protocols +
                certain AWID® 125-kHz Proximity protocol
              </span>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="my-tab px-[10px] lg:px-[50px] 2xl:px-[180px] xl:px-[180px]">
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default ProductTab;
