import React from "react";

const WarrantyInfo = () => {
  return (
    <div className="custom-padding mt-[30px]">
      <h2 className="text-[#121212] text-[24px] font-bold mb-[20px]">
        Warranty Policy
      </h2>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify text-justify mb-[20px]">
        Sicunet Inc. warrants its products to be free from defects in material
        and workmanship subject to the terms set forth herein (“Warranty”). The
        length of this Warranty is based on the product and or product category
        listed below. This limited Warranty only extends to those customers who
        buy direct from Sicunet Inc. or through Sicunet Inc’s authorized
        distribution channels. Warranty coverage time frame is determined by
        product date code or if product is serialized by the date the product is
        shipped to the customer from our distribution centers. 
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        NOTE THAT ALL SICUNET PRODUCTS ARE DESIGNED TO BE INSTALLED AND SERVICED
        BY TRAINED PROFESSIONALS. 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The user is responsible for all labor costs associated with removing,
        reinstalling and returning the product Sicunet. Sicunet, at its option,
        will repair or replace the defective product. 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Replacements may be made from b-stock products. If an exact replacement
        is not available, Sicunet, at its option, will select the nearest
        equivalent product. Sicunet will return warranted repaired or
        replacements by UPS Ground or an equivalent service. 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        A customer may pay the additional costs for second day or next-day
        service. All goods requiring warranty coverage shall be sent freight
        prepaid to a Sicunet designated location. Sicunet assumes no risk and
        shall be subject to no liability for damages or loss resulting from the
        specific use or application made of the Products.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
         Sicunet Inc.’s liability for any claim, whether based on breach of
        contract, negligence, infringement of any rights of any party or product
        liability, relating to the Products shall not exceed the price paid by
        the Dealer to Sicunet for such Products. In no event will Sicunet be
        liable for labor cost or other reimbursements for any special,
        incidental or on sequential damages (including loss of use, loss of
        profit and claims of third parties) however caused, whether by the
        negligence of Sicunet or otherwise of any kind. 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The above warranty provides the Dealer with specific legal rights. The
        Dealer may also have additional rights, which are subject to variation
        from state to state. Dealer Name Contact phone number and email Model
        and serial number or MAC address Date Code Details of the defect or
        problem If there is a dispute regarding the warranty of a product which
        does not fall under the warranty conditions stated above, please include
        a written explanation with the product when returned. Method of return
        shipment shall be the same or equal to the method by which the item was
        received by Sicunet. 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The duration of Sicunet Inc.’s Warranty is set forth below: 
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        Neptune Product Line – 5 Years All returns are to be sent back to: 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        RMA# Sicunet Inc. <br />
        1839 Eagle Farms Rd. <br />
        Chester Springs, PA 19425
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        Please be sure the product is packaged appropriately so that is not
        damaged in shipping upon return.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        If a warranty repair is required, the Dealer must contact Sicunet
        technical support at 857-346-0130 
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Ext: 2 to obtain a Return Material Authorization number (RMA). In order
        to obtain an RMA number, the caller will be asked to provide the
        following information:
      </p>
      <ul className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px] ml-[30px] list-disc">
        <li>Dealer Name</li>
        <li>Contact phone number and email</li>
        <li>Date Code</li>
        <li>Details of the defect or problem</li>
      </ul>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        If there is a dispute regarding the warranty of a product which does not
        fall under the warranty conditions stated above, please include a
        written explanation with the product when returned. Method of return
        shipment shall be the same or equal to the method by which the item was
        received by Sicunet. The duration of Sicunet Inc.’s Warranty is set
        forth below:
      </p>
      <p className="text-[16px] font-semibold text-[#222226]">
        Neptune Product Line – 5 Years <br />
        All returns are to be sent back to: <br />
        RMA# <br />
        Sicunet Inc. <br /> 1839 Eagle Farms Rd., <br /> Chester Springs, PA
        19425
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Please be sure the product is packaged appropriately so that is not
        damaged in shipping upon return.
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        Please download RMA Form.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Warranty Exceptions
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        1. The warranties do not apply to:
      </p>
      <ul className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px] ml-[30px] list-disc">
        <li>Damage incurred in shipping or handling;</li>
        <li>
          Damage caused by disaster such as fire, flood, wind, earthquake or
          lightning;
        </li>
        <li>
          Damage due to causes beyond the control of Sicunet Inc. such as
          excessive voltage, mechanical shock or water damage;
        </li>
        <li>
          Damage caused by unauthorized attachment, alterations, modifications
          or foreign objects being used with or in conjunction with the product;
        </li>
        <li>
          Damage caused by peripherals, sensors or other products used in
          connection with the products;
        </li>
        <li>
          Defects caused by failure to provide a suitable installation
          environment for the products;
        </li>
        <li>
          Damage caused by use of the products for purposes other than those for
          which they were designed;
        </li>
        <li>Damage from improper maintenance or installation;</li>
        <li>
          Damage arising out of any other abuse, mishandling or improper
          application of the products;
        </li>
        <li>
          Damage resulting from disassembly or repair in such a manner as to
          adversely affect performance or prevent adequate inspection or testing
          to verify any warranty claim;
        </li>
        <li> Returns based on product revision level or software version.</li>
      </ul>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        2. Product warranties set forth herein are void if the product has been
        tampered with, including but not limited to the date code, labels or
        other markings on the product.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        3. Products that are damaged in transit to Sicunet Inc. will not be
        covered under these warranties. If products are damaged or lost by the
        carrier, it is the sender’s risk and responsibility to create a claim
        against the carrier if they chose to do so.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Overstock Inventory All product returned to Sicunet Inc. as overstock
        must have an RMA#., please call ask to talk to your Customer Service
        Representative.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The following information must first be made available:
      </p>
      <ol className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        <li>1. Customer Name.</li>
        <li>2. Shipping Address.</li>
        <li>3. Telephone Number.</li>
        <li>4. Contact Name.</li>
        <li>5. The Part Number(s) being returned.</li>
        <li>6. The Quantity of each item being returned.</li>
        <li>7. The Product’s Date Code.</li>
      </ol>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        The following terms apply to overstock product returns for exchange:
      </p>
      <ol className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        <li>
          1. All overstock returns will be required to provide a minimum
          offsetting PO / order equal to equipment value to be returned. Sicunet
          Inc. will not provide a monetary refund in anything other than Sicunet
          Inc. products.
        </li>
        <li>
          2. There may be a 25% restocking fee on all accepted products for
          overstock. A PO must be attached to the RMA# authorizing this
          restocking fee. The restocking fee will be waived if a new PO for a
          minimum of 150% of the value of the returned product is received with
          the RMA request.
        </li>
        <li>
          3. The cost of shipping to Sicunet Inc. is the responsibility of the
          sender.
        </li>
        <li>
          4. Product that has been used cannot be returned for credit. Return of
          used product must follow the regular RMA procedures.
        </li>
        <li>
          5. RMA’s are valid for 30 days, after which time the RMA# is canceled
          and will not be accepted. The product will be returned at the sender’s
          expense. A new RA# must be obtained before the product can be
          returned.
        </li>
        <li>
          6. All returned products must be in new resalable condition and have
          its original packaging intact.
        </li>
        <li>
          7. Products to be returned must be a current sellable part and deemed
          to be viable inventory.
        </li>
      </ol>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        Advanced Replacements
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Sicunet Inc. does not issue advance replacements for defective
        equipment. Dealers and distributors are expected to carry stock in order
        to service their customers for replacement purposes. (See RMA Policy for
        repair procedures). If the requestor has an established credit line,
        Sicunet may consider sending Advance Replacements for equipment on an
        emergency basis and subject to the following. If it is determined by
        Technical Support that a product is defective, in warranty, and in
        immediate need of replacement may at its discretion issue a return
        material authorization and enter a Sales Order to accommodate the
        request for replacement product. The customer will be charged for the
        replacement product. Sicunet Inc. will pay for normal shipping charges,
        any expedited shipping (i.e. FedEx, UPS Red, UPS Blue, etc.) will be
        paid by the customer.
      </p>
      <p className="text-[16px] font-semibold text-[#222226] mb-[20px]">
        The following exceptions apply to requests for Advance Replacements:
      </p>
      <ol className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        <li>
          1. A Sales Order and Invoice will be generated authorizing the advance
          replacement. The customer will be charged for the replacement product.
        </li>
        <li>
          2. The customer must provide a with a Purchase Order at the time that
          the replacement product request is made. This Purchase Order is
          required to cover the cost of the product and any expedited shipping
          costs.
        </li>
        <li>
          3. The customer has 30 days to return the product in order to receive
          the offsetting credit to their account. If the product is not received
          within 30 days, no credit will be issued and the payment for the
          replacement product will be due immediately.
        </li>
        <li>
          4. If the product returned does not meet the warranty requirements for
          normal wear and use, the product will be returned at the sender’s
          expense. No credit will be issued and payment for the replacement
          product will be due immediately.
        </li>
        <li>
          5. Returned equipment should be a package in the same packaging as the
          replacement product to avoid additional damage in shipment.
        </li>
        <li>
          6. The RMA# must be clearly indicated on the outside of the shipping
          carton. Product that is returned without the proper labeling will be
          returned at the sender’s expense.
        </li>
        <li>
          7. The cost of shipping to Sicunet is the responsibility of the
          sender. An offsetting credit will be processed within 30 days of
          receipt of the product.
        </li>
        <li>
          8. All other criteria for RMA’s will apply as stated in the above
          policies.
        </li>
      </ol>
    </div>
  );
};

export default WarrantyInfo;
