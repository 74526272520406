import React from "react";
import { Dropdown, Space } from "antd";
import { ArrowDown } from "../Icons/Arrowdown";

export const CustomDropdown = ({ label, items }) => (
  <Dropdown
    menu={{
      items,
    }}
    trigger={["click", "hover"]}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        {label}
        <ArrowDown />
      </Space>
    </a>
  </Dropdown>
);
