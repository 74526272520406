import React from "react";
import "../Style/warrantypolicy.css";
import WarrantyPolicyBanner from "../Components/WarrantyPolicyBanner";
import WarrantyInfo from "../Components/WarrantyInfo";

const WarrantyPolicy = () => {
  return (
    <>
      <WarrantyPolicyBanner />
      <WarrantyInfo />
    </>
  );
};

export default WarrantyPolicy;
