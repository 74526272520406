import React from "react";
export const Earth = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#FCFDFF"
      d="M24 6c1.689 0 3.617 1.517 5.2 4.906.713 1.53 1.306 3.348 1.738 5.38H17.062c.432-2.032 1.025-3.85 1.739-5.38C20.383 7.517 22.31 6 24 6Zm-7.53 3.82c-.867 1.858-1.56 4.049-2.032 6.466H7.73A18.052 18.052 0 0 1 18.123 6.98a17.146 17.146 0 0 0-1.652 2.839Zm-2.442 9.037A41.565 41.565 0 0 0 13.714 24c0 1.773.108 3.497.314 5.143H6.746A18 18 0 0 1 6 24c0-1.788.26-3.514.746-5.143h7.282Zm.41 12.857c.473 2.418 1.165 4.608 2.033 6.467a17.02 17.02 0 0 0 1.652 2.839 18.052 18.052 0 0 1-10.392-9.306h6.707Zm2.623 0h13.877c-.432 2.032-1.025 3.85-1.739 5.38C27.617 40.483 25.69 42 24 42s-3.617-1.517-5.2-4.906c-.713-1.53-1.306-3.348-1.738-5.38m14.32-2.571h-14.76A39.449 39.449 0 0 1 16.287 24c0-1.791.119-3.516.335-5.143h14.76c.217 1.627.334 3.352.334 5.143 0 1.792-.118 3.516-.334 5.143Zm2.182 2.571h6.706a18.05 18.05 0 0 1-10.392 9.306 17.14 17.14 0 0 0 1.653-2.84c.867-1.858 1.56-4.05 2.033-6.466Zm7.692-2.571h-7.282c.205-1.646.313-3.37.313-5.143 0-1.772-.108-3.497-.313-5.143h7.282c.485 1.629.746 3.355.746 5.143 0 1.788-.26 3.514-.746 5.143ZM29.876 6.981a18.052 18.052 0 0 1 10.393 9.305h-6.707c-.473-2.417-1.165-4.608-2.033-6.466a17.153 17.153 0 0 0-1.652-2.84ZM24 44.57c11.36 0 20.571-9.21 20.571-20.57 0-11.361-9.21-20.572-20.571-20.572-11.36 0-20.571 9.21-20.571 20.571 0 11.36 9.21 20.572 20.571 20.572Z"
    />
  </svg>
);
