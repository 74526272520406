import React from "react";
import "../Style/featureTopBar.css";

export const FeatureTopBar = () => {
  return (
    <div className="e3-bg flex justify-center items-center">
      <p className="custom-text text-center">
        SICUNET{" "}
        <span className="hidden lg:inline">
          <br />
        </span>
        FEATURES
      </p>
    </div>
  );
};
