import React from "react";
import PrivacyPolicyBanner from "../Components/PrivacyPolicyBanner";
import PrivacyInfo from "../Components/PrivacyInfo";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyBanner />
      <PrivacyInfo />
    </>
  );
};

export default PrivacyPolicy;
