import CloudCardOne from "../../../assets/images/cloud-card-one.png";
import CloudCardTwo from "../../../assets/images/cloud-card-two.png";
import CloudCardThree from "../../../assets/images/cloud-card-three.png";

export const cloudCardItems = [
  {
    id: 1,
    title: "Remote Management Connector",
    description: `Dealers need access to simplify set up, on going configuration
              support and deliver agreed upon SLA and system services. End users
              want to co manage the system with the flexibility of not having to
              be in the facility or with specific computers.`,
    details: {
      brief: `The economic engine of our industry is driven by month charged services. The goal of RMC is to deliver a suite of services that maximizes the (ROI) on the client’s initial investment while providing capabilities to lower indirect costs of systems use (Low TCO)`,
      list: [
        "Today’s contracts values are sold at a 35X",
        "Every sales should a service contract",
        "RMC offer a suite of services that provide tangible enhancements to each technology deployment.",
      ],
    },
    image: CloudCardOne,
  },
  {
    id: 2,
    title: "Hosted RMC Server",
    description: `One or Many:Designed For scale securely connect to and remotely manage thousands of sites. Secure HTTPS Tunneling Technology—Foundational connectivity technology that securely links systems to RMC server. Features:`,
    details: {
      brief: "",
      list: [
        "Logging in and clicking link initiates a hyperlink to remote systems Secure HTTPS connection to the client",
        "The RMC server creates a secure, bonded and encrypted connection initiated from inside the firewall",
        "Maintain, configure user/role management Identical user experience as if logged onto the Neptune System over the primary network",
        "All system data is still maintained at the Edge – System integrity is strong Customer owns the system and data. Foundational Layer for Services Platform Modules",
      ],
    },
    image: CloudCardTwo,
  },
  {
    id: 3,
    title: "Hybrid Central Management via Cloud",
    description: `Dealers need access to simplify set up, on going configuration
              support and deliver agreed upon SLA and system services. End users
              want to co manage the system with the flexibility of not having to
              be in the facility or with specific computers.`,
    details: {
      brief: "",
      list: [
        "Remote Management Connector “RMC” links systems via SSL connection to Cloud for a direct link to field deployed system",
        "Best of both worlds with on-line and remote access to your systems",
        "The system and data is maintained at the edge",
        "Customers maintain 100% control",
        "Environment conditions selects option for management – Client owns the system and is not dependent on cloud 100%",
      ],
    },
    image: CloudCardThree,
  },
];
