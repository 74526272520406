import React from "react";
import "../Style/productDetails.css";
import ProductTopWrapper from "../Components/ProductTopWrapper";
import Description from "../Components/Description";
import ProductTab from "../Components/ProductTab";
const ProductDetails = () => {
  return (
    <div>
      <ProductTopWrapper />
      <Description />
      <ProductTab />
    </div>
  );
};

export default ProductDetails;
