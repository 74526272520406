import React from "react";
import "../Style/releaseNote.css";
import ReleaseBanner from "../Components/ReleaseBanner";
import ReleaseInfo from "../Components/ReleaseInfo";
const ReleaseNote = () => {
  return (
    <>
      <ReleaseBanner />
      <ReleaseInfo />
    </>
  );
};

export default ReleaseNote;
