import React from "react";
import RecordingOne from "../../../../assets/images/recording-one.png";
import RecordingTwo from "../../../../assets/images/recording-two.png";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import { FeatureCard } from "../../../../global/components/FeatureCard/FeatureCard";

export const VideoRecordingContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="my-16 custom-padding">
        <div className="flex flex-col justify-center h-full mb-16">
          <p className="text-2xl font-semibold pb-8">Thumb Drive Video Event</p>
          <p className="font-light">
            Sicunet Access control has an integrated ONVIF profile S video
            storage solution. Our platforms use this technology to provide
            several capabilities. First it provides a low-cost alternative
            solution to store tagged video event. This function allows each door
            panel to store up to 5,000 60 second video clips. This function can
            be used to record from an IP camera and or store events from our
            video intercom product. Many IP cameras offer two channels of video
            out. One which can be directed to the recording device such as an
            NVR and the second to our thumb drive video storage solution.
          </p>
        </div>
        <FeatureCard
          image={RecordingOne}
          //   label="Smart Reports"
          description={`Select Thumb Drive NVR\nSelect the Server or Client\nAdd the IP address of the Server or Client / Clients\nDefault Ports\nID and Password\nThe unit will show the max channels\nWith this capability each server or client processes the video and stores to a thumb drive. In each 1, 2, 4 door controllers there is an USB port for the thumb drive. Each unit is limited to the same number of doors to video channels.`}
          imageLeft={true}
        />
        <FeatureCard
          image={RecordingTwo}
          //   label="SCHLAGE LOCKSET"
          description="Example: 1 Door Controller / Client can only video tag for one camera
Go to NVR ChannelsEnable the option (Click box)If the unit has multiple doors select oneClick the boxes you would like to have video tagged"
          imageLeft={false}
        />
      </div>
    </>
  );
};
