import React from "react";

const EnergyConservation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.4999 16.4999C12.6112 16.4999 13.5555 16.1109 14.3329 15.3329C15.1109 14.5555 15.4999 13.6112 15.4999 12.4999V8.49985H11.4999C10.3885 8.49985 9.44418 8.88885 8.66685 9.66685C7.88885 10.4442 7.49985 11.3885 7.49985 12.4999C7.49985 12.9599 7.56452 13.4025 7.69385 13.8279C7.82318 14.2532 8.03652 14.6362 8.33385 14.9769L7.64585 15.6469C7.53918 15.7529 7.48585 15.8705 7.48585 15.9999C7.48585 16.1292 7.53918 16.2472 7.64585 16.3539C7.75252 16.4605 7.87052 16.5139 7.99985 16.5139C8.12918 16.5139 8.24718 16.4605 8.35385 16.3539L9.02285 15.6659C9.37618 15.9499 9.76618 16.1599 10.1929 16.2959C10.6182 16.4319 11.0539 16.4999 11.4999 16.4999ZM11.4999 15.4999C11.1832 15.4999 10.8762 15.4575 10.5789 15.3729C10.2815 15.2882 10.0065 15.1505 9.75385 14.9599L12.3539 12.3539C12.4605 12.2472 12.5139 12.1292 12.5139 11.9999C12.5139 11.8705 12.4605 11.7525 12.3539 11.6459C12.2472 11.5392 12.1292 11.4859 11.9999 11.4859C11.8705 11.4859 11.7525 11.5392 11.6459 11.6459L9.03985 14.2459C8.84918 13.9932 8.71152 13.7182 8.62685 13.4209C8.54218 13.1235 8.49985 12.8165 8.49985 12.4999C8.49985 11.6599 8.78985 10.9499 9.36985 10.3699C9.94985 9.78985 10.6599 9.49985 11.4999 9.49985H14.4999V12.4999C14.4999 13.3399 14.2099 14.0499 13.6299 14.6299C13.0499 15.2099 12.3399 15.4999 11.4999 15.4999ZM11.9999 21.5769L8.99585 19.2269L5.23085 18.7689L4.77385 15.0039L2.42285 11.9999L4.77285 8.99585L5.23085 5.23085L8.99585 4.77385L11.9999 2.42285L15.0039 4.77285L18.7689 5.23085L19.2259 8.99585L21.5769 11.9999L19.2269 15.0039L18.7689 18.7689L15.0039 19.2259L11.9999 21.5769ZM11.9999 20.2969L14.6019 18.2839L17.8909 17.8659L18.2839 14.6019L20.2959 11.9999L18.2839 9.39785L17.8659 6.13385L14.6019 5.71685L11.9999 3.70385L9.39785 5.71685L6.10885 6.13385L5.71685 9.39785L3.70385 11.9999L5.71685 14.6019L6.13385 17.8909L9.39785 18.2839L11.9999 20.2969Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default EnergyConservation;
