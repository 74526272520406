import { Divider } from "antd";
import React from "react";

const Description = () => {
  return (
    <div className="px-[10px] lg:px-[50px] 2xl:px-[180px] xl:px-[180px] mb-[56px]">
      <Divider />
      <h2 className="text-[#121212] text-[16px] font-semibold mb-[16px]">
        Description
      </h2>
      <p className="text-[#51525B] text-[13px] text-justify">
        Pyramid Series Proximity® from Farpointe Data sets the electronic
        security benchmark for 125-kHz proximity readers, cards, and tags. Based
        upon proven contactless digital radio frequency identification (RFID)
        technology, Pyramid readers interface with a wide range of electronic
        access control systems by complying with the Wiegand communication
        protocol. They offer value-add features such as MAXSecure™ and
        fleaPower™, and can be ordered to support several proximity card and tag
        technologies. Additionally, Pyramid cards and tags are passive devices,
        eliminate maintenance by requiring no battery, and can be ordered to
        support several proximity reader technologies.
      </p>
    </div>
  );
};

export default Description;
