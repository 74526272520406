export const collapsedItems = [
  {
    key: "1",
    label: <div className="">Overview</div>,
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address. This remote capability
        is integrated into our network appliance, providing secure,
        authenticated remote access to systems for the purpose of set up,
        management and remote control. The cloud is not the remote system
        server. All data and performance capabilities are managed at the edge.
        Capabilities are the same as on site or off site remotely. This service
        is a foundational tool to lower install, service costs while and
        Recurring Monthly Revenue (RMR) generation for our dealers.
      </p>
    ),
  },
  {
    key: "2",
    label: "What is RMC",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
  {
    key: "3",
    label: "Why Cloud Based RMC",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
  {
    key: "4",
    label: "How RMC Works",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
  {
    key: "5",
    label: "RMC Use Case",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
  {
    key: "6",
    label: "Security & Privacy",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
  {
    key: "7",
    label: "Web Compatibility",
    children: (
      <p>
        Remote Management Connection (RMC)Service is cloud service that allows a
        service provider one user interface (UI) to easily identify and remotely
        connect one or many access systems. RMC cloud provides a secure link to
        each of your field deployed system and then access to each system via a
        username and password. RMC is designed to provide secure connectivity to
        the access control systems that are located behind a NAT router or
        firewall, or those without a public IP address.{" "}
      </p>
    ),
  },
];
