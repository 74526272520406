import React from "react";
import { MobileLinkContent } from "../Component/MobileLinkContent";

const MobileLink = () => {
  return (
    <>
      <MobileLinkContent />
    </>
  );
};

export default MobileLink;
