import React from "react";
import "../Style/about.css";
import { Fingerprint } from "../../../global/components/Icons/Fingerprint";
import { Earth } from "../../../global/components/Icons/Earth";
import { Home } from "../../../global/components/Icons/Home";
import { Mail } from "../../../global/components/Icons/Mail";
import { Msg } from "../../../global/components/Icons/Msg";
import AboutOne from "../../../assets/images/about-one.png";
import AboutTwo from "../../../assets/images/about-two.png";
import AboutThree from "../../../assets/images/about-three.png";
import { FeatureCard } from "../../../global/components/FeatureCard/FeatureCard";

const AboutContent = () => {
  return (
    <div className="">
      <div className="image-container flex justify-center items-center">
        <div className="header-txt text-center">
          <p className="">Challenge Yourself</p>
          <p>Take It To The Next Level</p>
        </div>
      </div>

      {/* side by side image and content section */}
      <div className="py-16 custom-padding bg-sky-50">
        <FeatureCard
          image={AboutOne}
          label="About Sicunet"
          description="Sicunet Inc. is based in Norwood, Massachusetts. The company has
                beenan OEM designer and manufacturer of access control and video
                technologies for 18 years. We have over 100K systems field
                deployed and have a broad spectrum of installation types. Our
                executive team has over 200 years of knowledge working in the
                access control market. Our approach to business is to be honest,
                provide top service and products that are feature rich beyond
                our competitors."
          imageLeft={true}
        />
        <FeatureCard
          image={AboutThree}
          label="Technology that delivers a lower long-term cost of ownership"
          description="Sicunet has a full product line-up of browser managed access
                control platform that can be managed on premise and or via the
                cloud. Offering 1, 2, 4 and 8 door panels all the hardware
                placement to be optimized to reduce wiring. And integrated power
                for a simplified and more reliable deployment. Our cutting edge
                technology easily allows the the user to manage and monitor your
                facilities doors and or any device you would like to control and
                know who and when someone has access to the area or device.
                Access control, Video verification and Security monitoring is at
                the heart of the technology."
          imageLeft={false}
        />
        <FeatureCard
          image={AboutTwo}
          label="With over 100,000 systems deployed world wide. Our Solutions are
                for designed to secure any facility"
          description="Image of each vertical K-12 Education, Houses of Worship,
                Multi-tenant, Commercial, Banking, Healthcare"
          imageLeft={true}
        />
      </div>

      {/* Quality and Credibility Section */}
      <div className="flex flex-col items-center py-16 lg:py-24 custom-padding">
        <p className="text-[20px] sm:text-[38px] lg:text-[45px] text-center font-semibold pb-8">
          Quality and Credibility
        </p>
        <p className="font-light text-center">
          We design, build and manage all our software code! We control and test
          our products to the latest meet and exceed the latest network
          standards. All products are 100% tested and designed to ensure if they
          need service the database integrity remains secure and intact. We take
          pride in our service and trained dealer network
        </p>
      </div>
      {/* BG & Text Section */}
      <div className="flex justify-between items-center feedback-section px-4 md:px-20 xl:px-72">
        <div className="test-bg flex justify-between items-center">
          <div className="w-2/3 md:w-1/2">
            <p className="font-thin text-white ">
              Our technology has evolved from feedback from our customers and
              your requirements. With many years of development and enhancements
              you will discover many niceties within the system.
              feedback@sicunet.com
            </p>
          </div>
          <div className="flex pt-2">
            <Fingerprint />
            <div className="pl-4 sm:pl-16">
              <Earth />
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 flex flex-col items-center custom-padding">
        <p className="text-[20px] sm:text-[38px] lg:text-[45px] font-semibold pb-8 text-center">
          We are here to assist you
        </p>
        <p className="font-light text-center">
          Feel free to contact us or just drop a line here. Our support stuff
          will reach you very soon
        </p>
        <p className="text-center">
          Monday - Friday <br />
          8:00 am EST - 7:00 pm EST
        </p>
      </div>

      <div className="flex justify-center items-center flex-col sm:flex-row mb-16 custom-padding">
        <div className="flex flex-col mb-8 sm:mb-0 min-h-72 border border-gray-400 p-7 lg:p-8 rounded-md w-full sm:w-[33%] sm:mr-4">
          <div className="pb-8">
            <Home />
          </div>
          <p className="text-xl font-semibold pb-6">Head Quarter</p>
          <p className="">
            Sicunet Inc.
            <br />
            129A Morgan Drive <br />
            Norwood, MA 02062
          </p>
        </div>
        <div className="flex flex-col mb-8 sm:mb-0 min-h-72 border border-gray-400 p-8 rounded-md w-full sm:w-[33%] sm:mr-4">
          <div className="pb-8">
            <Mail />
          </div>
          <p className="text-xl font-semibold pb-6">Mailing Address</p>
          <p className="">
            sales@sicunet.com <br />
            tech@sicunet.com
          </p>
        </div>
        <div className="flex flex-col min-h-72 border border-gray-400 p-8 rounded-md w-full sm:w-[33%]">
          <div className="pb-8">
            <Msg />
          </div>
          <p className="text-xl font-semibold pb-6">Business Talk</p>
          <p className="">857-346-0130</p>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
