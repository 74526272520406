import { Checkbox, Divider, Input, Select } from "antd";
import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const Filter = () => {
  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const options = [
    {
      value: "Controller",
      label: "Controller",
    },
    {
      value: "Reader",
      label: "Reader",
    },
    {
      value: "Credential",
      label: "Credential",
    },
    {
      value: "Accessory",
      label: "Accessory",
    },
    {
      value: "License",
      label: "License",
    },
  ];

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  return (
    <>
      <div className="search-product block lg:hidden">
        <Input
          placeholder="Search Product..."
          suffix={<BiSearchAlt2 />} // Adds the search icon inside the input
          allowClear
          className="!bg-white w-full my-2"
          // style={{
          //   width: 296,
          // }}
        />
      </div>
      <div className="mt-2 md:mt-4 lg:mt-20 flex flex-row lg:flex-col gap-[15px]">
        <div className="search-product hidden lg:block">
          <Input
            placeholder="Search Product..."
            suffix={<BiSearchAlt2 />} // Adds the search icon inside the input
            allowClear
            className="!bg-white w-[195px] md:w-full"
            // style={{
            //   width: 296,
            // }}
          />
        </div>
        <div className="search-by my-0 lg:my-[10px] w-1/2 md:w-full">
          <p className="text-[#121212] mb-[8px] text-[16px] hidden lg:block font-semibold">
            Sort Product By
          </p>
          <Select
            // style={{
            //   width: 296,
            // }}
            placeholder="Sort By"
            onChange={handleChange}
            className="w-full"
            options={[
              {
                value: "asc",
                label: "Ascending",
              },
              {
                value: "desc",
                label: "Descending",
              },
            ]}
          />
        </div>
        <div className="categories w-1/2 md:w-full">
          <p className="text-[16px] font-semibold text-[#121212] hidden lg:block">
            Categories
          </p>
          <Divider className="hidden lg:block" />
          <Select
            mode="multiple"
            allowClear
            // style={{ width: "100%" }}
            className="md:w-full block lg:hidden"
            placeholder="Categories"
            onChange={handleChange}
            options={options}
          />
          <Checkbox.Group
            options={options}
            onChange={onChange}
            className="lg:flex flex-col hidden"
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
