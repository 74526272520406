import { Divider } from "antd";
import React from "react";
import UniqueSecurity from "../../../global/components/Icons/UniqueSecurity";
import EnergyConservation from "../../../global/components/Icons/EnergyConservation";
import BoostingTechnology from "../../../global/components/Icons/BoostingTechnology";
import LifeTime from "../../../global/components/Icons/LifeTime";

const ProductInfo = () => {
  return (
    <div>
      <h1 className="text-[24px] text-[#121212] font-semibold line-clamp-2">
        PATAGONIA PROXIMITY READER & KEYPAD
      </h1>
      <p className="text-[#51525B] text-[16px]">
        Pyramid Series Proximity® from Farpointe Data sets the electronic
        security benchmark for 125-kHz.
      </p>
      <Divider />
      <ul>
        <li className="flex mb-[24px] ">
          <UniqueSecurity />
          <span className="ml-[8px] text-[#0D0D0D] text-[16px]">
            Unique Security features
          </span>
        </li>
        <li className="flex mb-[24px]">
          <EnergyConservation />
          <span className="ml-[8px] text-[#0D0D0D] text-[16px]">
            Energy Conservation technology
          </span>
        </li>
        <li className="flex mb-[24px]">
          <BoostingTechnology />
          <span className="ml-[8px] text-[#0D0D0D] text-[16px]">
            Read Range Boosting technology
          </span>
        </li>
        <li className="flex mb-[24px]">
          <LifeTime />
          <span className="ml-[8px] text-[#0D0D0D] text-[16px]">
            Lifetime warrantee
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ProductInfo;
