import React from "react";

const BuyNow = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2812 6.59375C13.5312 6.86458 13.6562 7.1875 13.6562 7.5625C13.6562 7.91667 13.5312 8.22917 13.2812 8.5L8.5 13.2812C8.25 13.5312 7.9375 13.6562 7.5625 13.6562C7.1875 13.6562 6.875 13.5312 6.625 13.2812L0.71875 7.375C0.46875 7.125 0.34375 6.8125 0.34375 6.4375V1.65625C0.34375 1.30208 0.46875 1 0.71875 0.75C0.989583 0.479167 1.30208 0.34375 1.65625 0.34375H6.4375C6.8125 0.34375 7.125 0.46875 7.375 0.71875L13.2812 6.59375ZM3.34375 4.34375C3.61458 4.34375 3.84375 4.25 4.03125 4.0625C4.23958 3.85417 4.34375 3.61458 4.34375 3.34375C4.34375 3.05208 4.23958 2.8125 4.03125 2.625C3.84375 2.4375 3.61458 2.34375 3.34375 2.34375C3.05208 2.34375 2.8125 2.4375 2.625 2.625C2.4375 2.8125 2.34375 3.05208 2.34375 3.34375C2.34375 3.61458 2.4375 3.85417 2.625 4.0625C2.8125 4.25 3.05208 4.34375 3.34375 4.34375Z"
        fill="#FEFFFF"
      />
    </svg>
  );
};

export default BuyNow;
