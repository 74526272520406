import React from "react";
export const Msg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#4B68C6"
      d="M49 14v28a1.75 1.75 0 0 1-1.75 1.75H17.5l-7.623 6.587A1.75 1.75 0 0 1 7 49V14a1.75 1.75 0 0 1 1.75-1.75h38.5A1.75 1.75 0 0 1 49 14Z"
      opacity={0.2}
    />
    <path
      fill="#4B68C6"
      d="M47.25 10.5H8.75a3.5 3.5 0 0 0-3.5 3.5v35a3.467 3.467 0 0 0 2.021 3.172 3.478 3.478 0 0 0 3.721-.499l.02-.015 7.144-6.158H47.25a3.5 3.5 0 0 0 3.5-3.5V14a3.5 3.5 0 0 0-3.5-3.5Zm0 31.5H17.5c-.42 0-.826.152-1.144.427L8.75 49V14h38.5v28Zm-28-17.5A1.75 1.75 0 0 1 21 22.75h14a1.75 1.75 0 0 1 0 3.5H21a1.75 1.75 0 0 1-1.75-1.75Zm0 7A1.75 1.75 0 0 1 21 29.75h14a1.75 1.75 0 0 1 0 3.5H21a1.75 1.75 0 0 1-1.75-1.75Z"
    />
  </svg>
);
