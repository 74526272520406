import React from "react";
import "../Style/releaseNote.css";
const ReleaseBanner = () => {
  return (
    <div className=" flex justify-center items-center text-white z-50 release-banner">
      <h1 className=" text-white text-[24px] md:text-[48px] font-semibold leading-[97px]">
        Release Note
      </h1>
    </div>
  );
};

export default ReleaseBanner;
