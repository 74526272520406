import React from "react";
export const SmallLogoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.689 2.112 4.233 13.293l2.969 1.714 6.455-11.181-2.968-1.714ZM8.01 15.098c.19-.028.36-.089.525-.182l4.964-2.867c.46-.268.746-.76.746-1.291V5.026c0-.192-.032-.367-.104-.546-2.043 3.539-4.087 7.077-6.13 10.618ZM1.333 7.195v3.563c0 .532.286 1.024.746 1.292.503.288 1.006.58 1.509.87l1.915-3.317-4.17-2.408ZM7.753 5.7l-4.54-2.62c-.378.217-.756.434-1.134.655-.46.264-.746.757-.746 1.291v1.31c1.516.873 3.029 1.747 4.54 2.62.629-1.087 1.256-2.171 1.88-3.255ZM10.043 1.738c-.503-.289-1.006-.581-1.508-.87a1.485 1.485 0 0 0-1.491 0c-1.028.595-2.058 1.187-3.085 1.783 1.387.802 2.778 1.605 4.169 2.407.638-1.109 1.277-2.214 1.915-3.32Z"
    />
    <path
      fill="#fff"
      d="M10.689 2.112 4.233 13.293l2.969 1.714 6.455-11.181-2.968-1.714ZM8.01 15.098c.19-.028.36-.089.525-.182l4.964-2.867c.46-.268.746-.76.746-1.291V5.026c0-.192-.032-.367-.104-.546-2.043 3.539-4.087 7.077-6.13 10.618ZM1.333 7.195v3.563c0 .532.286 1.024.746 1.292.503.288 1.006.58 1.509.87l1.915-3.317-4.17-2.408ZM7.753 5.7l-4.54-2.62c-.378.217-.756.434-1.134.655-.46.264-.746.757-.746 1.291v1.31c1.516.873 3.029 1.747 4.54 2.62.629-1.087 1.256-2.171 1.88-3.255ZM10.043 1.738c-.503-.289-1.006-.581-1.508-.87a1.485 1.485 0 0 0-1.491 0c-1.028.595-2.058 1.187-3.085 1.783 1.387.802 2.778 1.605 4.169 2.407.638-1.109 1.277-2.214 1.915-3.32Z"
    />
  </svg>
);
