import React from "react";

const ApplicationNote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M21.3332 15.9997C20.2723 15.9997 19.2549 15.5782 18.5047 14.8281C17.7546 14.078 17.3332 13.0605 17.3332 11.9997V6.66634H6.6665C5.95926 6.66634 5.28098 6.94729 4.78089 7.44739C4.28079 7.94749 3.99984 8.62576 3.99984 9.33301V23.9997C3.99984 24.7069 4.28079 25.3852 4.78089 25.8853C5.28098 26.3854 5.95926 26.6663 6.6665 26.6663H23.9998C24.7071 26.6663 25.3854 26.3854 25.8855 25.8853C26.3856 25.3852 26.6665 24.7069 26.6665 23.9997V15.9997H21.3332ZM18.6665 11.9997C18.6665 12.7069 18.9475 13.3852 19.4476 13.8853C19.9477 14.3854 20.6259 14.6663 21.3332 14.6663H26.1198L18.6665 7.21301V11.9997ZM6.6665 5.33301H18.6665L27.9998 14.6663V23.9997C27.9998 25.0605 27.5784 26.078 26.8283 26.8281C26.0781 27.5782 25.0607 27.9997 23.9998 27.9997H6.6665C5.60564 27.9997 4.58822 27.5782 3.83808 26.8281C3.08793 26.078 2.6665 25.0605 2.6665 23.9997V9.33301C2.6665 8.27214 3.08793 7.25473 3.83808 6.50458C4.58822 5.75444 5.60564 5.33301 6.6665 5.33301ZM6.6665 10.6663H14.6665V11.9997H6.6665V10.6663ZM6.6665 15.9997H14.6665V17.333H6.6665V15.9997ZM6.6665 21.333H23.9998V22.6663H6.6665V21.333Z"
        fill="#6880CF"
      />
    </svg>
  );
};

export default ApplicationNote;
