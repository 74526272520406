import React from "react";
import "../Style/privacyPolicy.css";
const PrivacyPolicyBanner = () => {
  return (
    <div className=" flex justify-center items-center text-white z-50 privacy-banner">
      <h1 className=" text-white text-[24px] md:text-[48px] font-semibold leading-[97px]">
        Privacy Policy
      </h1>
    </div>
  );
};

export default PrivacyPolicyBanner;
