import React from "react";

const Heart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M22.25 9.5625C22.25 15.75 12.5 21 12.5 21C12.5 21 2.75 15.75 2.75 9.5625C2.75 8.21984 3.28337 6.93217 4.23277 5.98277C5.18217 5.03337 6.46984 4.5 7.8125 4.5C9.93031 4.5 11.7444 5.65406 12.5 7.5C13.2556 5.65406 15.0697 4.5 17.1875 4.5C18.5302 4.5 19.8178 5.03337 20.7672 5.98277C21.7166 6.93217 22.25 8.21984 22.25 9.5625Z"
        fill="#E80533"
      />
      <path
        d="M17.1875 3.75C15.2516 3.75 13.5566 4.5825 12.5 5.98969C11.4434 4.5825 9.74844 3.75 7.8125 3.75C6.27146 3.75174 4.79404 4.36468 3.70436 5.45436C2.61468 6.54404 2.00174 8.02146 2 9.5625C2 16.125 11.7303 21.4369 12.1447 21.6562C12.2539 21.715 12.376 21.7458 12.5 21.7458C12.624 21.7458 12.7461 21.715 12.8553 21.6562C13.2697 21.4369 23 16.125 23 9.5625C22.9983 8.02146 22.3853 6.54404 21.2956 5.45436C20.206 4.36468 18.7285 3.75174 17.1875 3.75ZM12.5 20.1375C10.7881 19.14 3.5 14.5959 3.5 9.5625C3.50149 8.41921 3.95632 7.32317 4.76475 6.51475C5.57317 5.70632 6.66921 5.25149 7.8125 5.25C9.63594 5.25 11.1669 6.22125 11.8062 7.78125C11.8628 7.91881 11.9589 8.03646 12.0824 8.11926C12.2059 8.20207 12.3513 8.24627 12.5 8.24627C12.6487 8.24627 12.7941 8.20207 12.9176 8.11926C13.0411 8.03646 13.1372 7.91881 13.1937 7.78125C13.8331 6.21844 15.3641 5.25 17.1875 5.25C18.3308 5.25149 19.4268 5.70632 20.2353 6.51475C21.0437 7.32317 21.4985 8.41921 21.5 9.5625C21.5 14.5884 14.21 19.1391 12.5 20.1375Z"
        fill="#E80533"
      />
    </svg>
  );
};

export default Heart;
