import React from "react";
export const Mail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#4B68C6"
      fillOpacity={0.2}
      stroke="#4B68C6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M4.704 31.444c.152 7.152.229 10.729 2.868 13.377 2.639 2.65 6.311 2.742 13.659 2.926 4.527.117 9.011.117 13.538 0 7.348-.184 11.02-.276 13.66-2.926 2.638-2.648 2.715-6.225 2.87-13.377.046-2.3.046-4.587 0-6.888-.155-7.152-.232-10.729-2.87-13.377-2.64-2.65-6.312-2.742-13.66-2.926a268.345 268.345 0 0 0-13.538 0c-7.348.184-11.02.275-13.66 2.926-2.638 2.648-2.715 6.225-2.87 13.377a161 161 0 0 0 .003 6.888Z"
    />
    <path
      stroke="#4B68C6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="m16.333 19.833 6.865 4.06c4.002 2.366 5.6 2.366 9.604 0l6.865-4.06"
    />
  </svg>
);
