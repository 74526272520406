import React from "react";

export const Cross = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeWidth={2}
        d="M20 20 4 4m16 0L4 20"
      />
    </svg>
  );
};
