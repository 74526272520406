import React from "react";
export const Home = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#4B68C6"
      d="M39.667 49H16.333a4.667 4.667 0 0 1-4.666-4.667v-21L28 7l16.333 16.333v21A4.667 4.667 0 0 1 39.667 49Z"
      opacity={0.16}
    />
    <path
      fill="#4B68C6"
      d="M46.667 23.333a2.333 2.333 0 1 0-4.667 0h4.667Zm-32.667 0a2.333 2.333 0 1 0-4.667 0H14Zm33.35 6.317a2.333 2.333 0 0 0 3.3-3.3l-3.3 3.3ZM28 7l1.65-1.65a2.333 2.333 0 0 0-3.3 0L28 7ZM5.35 26.35a2.333 2.333 0 1 0 3.3 3.3l-3.3-3.3Zm10.983 24.983h23.334v-4.666H16.333v4.666Zm30.334-7v-21H42v21h4.667Zm-32.667 0v-21H9.333v21H14ZM50.65 26.35l-21-21-3.3 3.3 21 21 3.3-3.3Zm-24.3-21-21 21 3.3 3.3 21-21-3.3-3.3Zm13.317 45.983a7 7 0 0 0 7-7H42a2.333 2.333 0 0 1-2.333 2.334v4.666Zm-23.334-4.666A2.333 2.333 0 0 1 14 44.332H9.333a7 7 0 0 0 7 7v-4.666Z"
    />
  </svg>
);
