import React from "react";
import TaggingOne from "../../../../assets/images/tagging-one.png";
import TaggingTwo from "../../../../assets/images/tagging-two.png";
import TaggingThree from "../../../../assets/images/tagging-three.png";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import { FeatureCard } from "../../../../global/components/FeatureCard/FeatureCard";

export const VideoTaggingContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="my-16 custom-padding">
        <FeatureCard
          image={TaggingOne}
          label="Video tagging and event viewing"
          description="Adding video integration with your access control provides the security team a faster and more efficient ability to see and confirm events."
          imageLeft={true}
        />
        <FeatureCard
          image={TaggingTwo}
          //   label="SCHLAGE LOCKSET"
          description="Video Pop up – Based on an event the system will display live video and the stored image of a user directly on the screen. This allows and administrator to compare the actual individual traveling through the door. The direct comparision is is automated and allows for a fast response should the video not match the photo image."
          imageLeft={false}
        />
        <FeatureCard
          image={TaggingThree}
          //   label="SCHLAGE LOCKSET"
          description="Video Tag – This feature significantly simplifies the verification of an event. “Seeing is believing” The mobile manager and a user logged into the user interface can quickly and easily see video associated with these events. The manager can now easy sort or create report of certain types of events. Key to this feature is the ability to click on the icon and view the history of the event. Example is you are investigating a theft. You see the system have several events where a person was granted access over the window in question. The manager can then click the icon and view if the individual that the system says entered the door with visual verification. The time to enter data from one system to the other is dramatically reduced. This lowers the TCO of your system will enhancing the response time for any potential emergency response."
          imageLeft={true}
        />
      </div>
    </>
  );
};
