import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SicunetIcon } from "../Icons/Sicunet";
import { SmallLogoIcon } from "../Icons/SmallLogoIcon";
import "./navbar.css";
import { CustomDropdown } from "../inputWidgets/CustomDropdown";
import { Button } from "antd";
import { MenuIcon } from "../Icons/MenuIcon";
import { Cross } from "../Icons/Cross";

const NavBar = () => {
  const navigate = useNavigate();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [menu, setMenu] = useState("Home");
  const [isAtTop, setIsAtTop] = useState(true);

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  // Close mobile drawer when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const drawer = document.getElementById("mobile-drawer");
      const dropdown = document.querySelector(".ant-dropdown");

      if (
        mobileDrawerOpen &&
        drawer &&
        !drawer.contains(event.target) &&
        (!dropdown || !dropdown.contains(event.target))
      ) {
        setMobileDrawerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileDrawerOpen]);

  // Detect scroll position and add/remove padding
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsAtTop(true); // At the top of the page
      } else {
        setIsAtTop(false); // Not at the top
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const items = [
    {
      label: (
        <a
          onClick={() => {
            navigate("/cloud");
            setMobileDrawerOpen(false);
          }}
        >
          Cloud
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/e3-takeover");
            setMobileDrawerOpen(false);
          }}
        >
          E3 Take over
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/mobile-link");
            setMobileDrawerOpen(false);
          }}
        >
          Mobile LINK
        </a>
      ),
      key: "2",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/nvr");
            setMobileDrawerOpen(false);
          }}
        >
          NVR Integration
        </a>
      ),
      key: "3",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/schlage-lockset");
            setMobileDrawerOpen(false);
          }}
        >
          Schlage Lockset
        </a>
      ),
      key: "4",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/smart-report");
            setMobileDrawerOpen(false);
          }}
        >
          Smart Reports
        </a>
      ),
      key: "5",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/video-recording");
            setMobileDrawerOpen(false);
          }}
        >
          Video Recording
        </a>
      ),
      key: "6",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/video-tagging");
            setMobileDrawerOpen(false);
          }}
        >
          Video tagging
        </a>
      ),
      key: "7",
    },
  ];

  const supportItems = [
    {
      label: (
        <a
          onClick={() => {
            navigate("/warrantypolicy");
            setMobileDrawerOpen(false);
          }}
        >
          Warranty Policy
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/privacypolicy");
            setMobileDrawerOpen(false);
          }}
        >
          Privacy Policy
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a
          onClick={() => {
            navigate("/releasenote");
            setMobileDrawerOpen(false);
          }}
        >
          Release Notes
        </a>
      ),
    },
  ];

  return (
    <>
      <div
        className={`fixed flex justify-between bg-[#0d0d0d] z-10 w-full py-4 custom-padding ${
          isAtTop ? "pt-4 md:pt-12" : ""
        }`}
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/");
            setMenu("Home");
          }}
        >
          <SicunetIcon />
        </div>
        <div className="flex">
          <div className="hidden lg:flex justify-between text-white">
            <ul className="flex space-x-3  xl:space-x-10 text-decoration">
              <li
                className={`${
                  menu === "Home" ? "border-b-2 border-[#4B68C6]" : ""
                }`}
                onClick={() => {
                  navigate("/");
                  setMenu("Home");
                }}
              >
                Home
              </li>
              <li
                className={`${
                  menu === "Company" ? "border-b-2 border-[#4B68C6]" : ""
                }`}
                onClick={() => {
                  navigate("/company");
                  setMenu("Company");
                }}
              >
                Company
              </li>
              <li
                className={`${
                  menu === "Product" ? "border-b-2 border-[#4B68C6]" : ""
                }`}
                onClick={() => {
                  navigate("/product");
                  setMenu("Product");
                }}
              >
                Our Products
              </li>
              <li
                className={`${
                  menu === "Key" ? "border-b-2 border-[#4B68C6]" : ""
                }`}
                onClick={() => {
                  setMenu("Key");
                }}
              >
                <CustomDropdown items={items} label="Key features" />
              </li>
              <li
                className={`${
                  menu === "Support" ? "border-b-2 border-[#4B68C6]" : ""
                }`}
                onClick={() => {
                  setMenu("Support");
                }}
              >
                <CustomDropdown items={supportItems} label="Support" />
              </li>
            </ul>
          </div>
          <div className="flex lg:pl-8 md:pl-20">
            <Button
              type="primary"
              className="hidden md:flex rounded-md font-semibold py-4"
              onClick={() => {
                navigate("/requestdemo");
                setMenu("");
              }}
            >
              <span>
                <SmallLogoIcon />
              </span>
              Request Demo
            </Button>
            <div className="lg:hidden flex justify-end ml-4 z-20 pb-1.5">
              {!mobileDrawerOpen ? (
                <Button
                  type="primary"
                  className="md:hidden rounded-md font-semibold py-2 mr-2"
                  onClick={() => {
                    navigate("/requestdemo");
                    setMenu("");
                  }}
                >
                  <span>
                    <SmallLogoIcon />
                  </span>
                  Demo
                </Button>
              ) : (
                <></>
              )}
              <button onClick={toggleNavbar} className="text-white">
                {mobileDrawerOpen ? <Cross /> : <MenuIcon />}
              </button>
            </div>
          </div>
        </div>

        {mobileDrawerOpen && (
          <div
            id="mobile-drawer"
            className="fixed right-0 z-20 bg-[#0d0d0d] w-full p-4 flex flex-col justify-center items-center lg:hidden mt-10 text-white cursor-pointer"
          >
            <ul className="text-center">
              <li
                className={`${
                  menu === "Home"
                    ? "border-b-2 border-[#4B68C6] mb-12 inline-block"
                    : "mb-12"
                }`}
                onClick={() => {
                  navigate("/");
                  setMenu("Home");
                  setMobileDrawerOpen(false);
                }}
              >
                Home
              </li>
              <li
                className={`${
                  menu === "Company"
                    ? "border-b-2 border-[#4B68C6] mb-12 inline-block"
                    : "mb-12"
                }`}
                onClick={() => {
                  navigate("/company");
                  setMenu("Company");
                  setMobileDrawerOpen(false);
                }}
              >
                Company
              </li>
              <li
                className={`${
                  menu === "Product"
                    ? "border-b-2 border-[#4B68C6] mb-12 inline-block"
                    : "mb-12"
                }`}
                onClick={() => {
                  navigate("/product");
                  setMenu("Product");
                  setMobileDrawerOpen(false);
                }}
              >
                Our Products
              </li>
              <li
                className={`${
                  menu === "Key"
                    ? "border-b-2 border-[#4B68C6] inline-block mb-12"
                    : "mb-12"
                }`}
                onClick={() => {
                  setMenu("Key");
                }}
              >
                <CustomDropdown items={items} label="Key features" />
              </li>
              <li
                className={`${
                  menu === "Support"
                    ? "border-b-2 border-[#4B68C6] mb-12 inline-block"
                    : "mb-12"
                }`}
                onClick={() => {
                  setMenu("Support");
                }}
              >
                <CustomDropdown items={supportItems} label="Support" />
              </li>
            </ul>
            <Button
              type="primary"
              className="sm:hidden rounded-md font-semibold py-4 mb-16"
              onClick={() => {
                navigate("/requestdemo");
                setMenu("");
              }}
            >
              <span>
                <SmallLogoIcon />
              </span>
              Request Demo
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;
