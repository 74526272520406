import { Button, Checkbox, Divider, Form, Input } from "antd";
import React from "react";
import SendIcon from "../../../global/components/Icons/SendIcon";

const ContactForm = () => {
  const handleFinish = (values) => {
    console.log("value", values);
  };
  return (
    <div className="bg-[#EFEEE6] py-[40px] px-[24px] max-w-3xl mx-auto rounded-2xl shadow-md flex">
      <div>
        <p className="text-[16px] text-[#007AFC]">Get in Touch </p>
        <h2 className="text-[24px] text-[#0D0D0D] font-normal mb-4">
          Design assistance with our product?
        </h2>
        <p className="text-[#8A8B91] text-[14px]">
          Call us and we can assist in designing a security solution that will
          best fit your application. Our extensive knowledge of software,
          hardware and integrations will ensure your system is properly designed
          and meets safety codes.
        </p>
        <Divider className="my-[48px]" />
        <Form
          layout="vertical"
          className="request-form"
          requiredMark={false}
          onFinish={handleFinish}
        >
          {/* Company Email */}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Name */}
            <Form.Item
              label="First Name*"
              name="firstname"
              rules={[
                { required: true, message: "Please input your First name!" },
              ]}
            >
              <Input placeholder="Mike Shaw" />
            </Form.Item>

            {/* Phone */}
            <Form.Item
              label="Last Name*"
              name="lastname"
              rules={[
                { required: true, message: "Please input your Last Name!" },
              ]}
            >
              <Input placeholder="+880-19191919191" />
            </Form.Item>
          </div>
          <Form.Item
            label="Email Address*"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input placeholder="example@company.com" />
          </Form.Item>
          {/* Message */}
          <Form.Item
            label="Message*"
            name="message"
            rules={[{ required: true, message: "Please leave a message!" }]}
            className="mb-[48px]"
          >
            <Input.TextArea rows={4} placeholder="Leave us a message ..." />
          </Form.Item>
          <Form.Item name="remember">
            <Checkbox>I agree to our friendly privacy policy</Checkbox>
          </Form.Item>
          {/* Request Button */}
          <Form.Item className=" float-right">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-[#007AFC] text-white w-full md:w-auto px-6 py-2 rounded-[20px] text-[16px]"
            >
              <SendIcon />
              Send
            </Button>
          </Form.Item>
        </Form>

        <Divider className="my-[48px]" />
        <p className="text-sm text-gray-500">
          By clicking "Book a Demo" you agree to our{" "}
          <a href="#" className="text-blue-500">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="#" className="text-blue-500">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
