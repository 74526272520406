import React from "react";
import contact from "../../../assets/contactus.png";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";

const SalesInfo = () => {
  return (
    <div>
      <div className="contact-box  mb-[24px]">
        <img
          src={contact}
          alt="Contact Us"
          className=" w-full h-full object-cover"
        />
      </div>
      <div className="bg-[#EFEEE6] rounded-[32px] p-[16px]">
        <div className="pl-[16px] py-[8px] bg-[#FEFFFF] rounded-[24px] flex mb-[16px]">
          <div className="bg-[#EFEEE6] p-[16px] rounded-full inline-block mr-[8px]">
            <IoMdMail color="#3A3A41" />
          </div>
          <div>
            <p className="text-[15px] font-medium text-[#0D0D0D]">Email</p>
            <p className="text-[14px] text-[#0D0D0D]">sales@sicunet.com</p>
          </div>
        </div>
        <div className="pl-[16px] py-[8px] bg-[#FEFFFF] rounded-[24px] flex">
          <div className="bg-[#EFEEE6] p-[16px] rounded-full inline-block mr-[8px]">
            <FaPhoneAlt color="#3A3A41" />
          </div>
          <div>
            <p className="text-[15px] font-medium text-[#0D0D0D]">Telephone</p>
            <p className="text-[14px] text-[#0D0D0D]">857-346-0130</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesInfo;
