import React from "react";
import AboutContent from "../Components/AboutContent";

//will considered as the homepage of the Home menu
const About = () => {
  return (
    <div>
      <AboutContent />
    </div>
  );
};

export default About;
