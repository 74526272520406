import React from "react";

export const FeatureCard = ({ image, label, description, imageLeft }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center mb-16">
      {imageLeft ? (
        <>
          <div className="w-full mb-12 lg:mb-0 lg:w-1/2 lg:mr-16">
            <img src={image} alt="Image" className="object-cover w-full" />
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col justify-center h-full">
              <p className={`text-2xl font-semibold ${label ? "pb-8" : ""}`}>
                {label}
              </p>
              <p className="font-light whitespace-pre-line">{description}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="hidden lg:block w-full mb-12 lg:mb-0 lg:w-1/2 lg:mr-16">
            <div className="flex flex-col justify-center h-full">
              <p className={`text-2xl font-semibold ${label ? "pb-8" : ""}`}>
                {label}
              </p>
              <p className="font-light whitespace-pre-line">{description}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
            <img src={image} alt="Image" className="object-cover w-full" />
          </div>
          <div className="lg:hidden w-full mb-12 lg:mb-0 lg:w-1/2 lg:mr-16">
            <div className="flex flex-col justify-center h-full">
              <p className={`text-2xl font-semibold ${label ? "pb-8" : ""}`}>
                {label}
              </p>
              <p className="font-light whitespace-pre-line">{description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
