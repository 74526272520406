import React from "react";

const ReleaseInfo = () => {
  return (
    <div className="custom-padding mt-[30px]">
      <p className="text-[24px] font-bold text-[#121212] mb-[20px]">
        Release Notes: NEPTUNE X.00-05c
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        NEPTUNE X.00-05c-June 28, 2023:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium mb-[20px]">
        Changed HLS Video Streaming to ActiveX Video <br />
        Enhanced Elevator Output Control <br />
        SSL Update <br />
        TLS Update
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        NEPTUNE X.00-05a-June 13, 2023:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium mb-[20px]">
        Added Sicunet Anti-virus utility. <br /> Added message to Network
        settings page:
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Important!!!
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium mb-[20px]">
        All IP addresses should be configured to a network that is within and
        protected by a network security system (Firewall,etc.) <br />
        If you require outside of firewall accessibility, contact the factory.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium mb-[20px]">
        Changed the backup retry time from 10 seconds to 10 minutes when a
        scheduled backup fails. <br /> Modified to reboot when DB lock occurs 3
        times. <br /> Elevator Relay supports Energized/De-Energized type.{" "}
        <br /> Updated PHP <br />
        Updated Checksum with Time Limit
      </p>
    </div>
  );
};

export default ReleaseInfo;
