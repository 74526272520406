import React from "react";
import ProductBanner from "../Components/ProductBanner";
import "../Style/product.css";
import ProductsAndFilter from "../Components/FilterProducts";
const Product = () => {
  return (
    <>
      <ProductBanner />
      <ProductsAndFilter />
    </>
  );
};

export default Product;
