import React from "react";

const UserMannual = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H20C20.1989 11.25 20.3897 11.329 20.5303 11.4697C20.671 11.6103 20.75 11.8011 20.75 12C20.75 12.1989 20.671 12.3897 20.5303 12.5303C20.3897 12.671 20.1989 12.75 20 12.75H12C11.8011 12.75 11.6103 12.671 11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12ZM12 16.75H20C20.1989 16.75 20.3897 16.671 20.5303 16.5303C20.671 16.3897 20.75 16.1989 20.75 16C20.75 15.8011 20.671 15.6103 20.5303 15.4697C20.3897 15.329 20.1989 15.25 20 15.25H12C11.8011 15.25 11.6103 15.329 11.4697 15.4697C11.329 15.6103 11.25 15.8011 11.25 16C11.25 16.1989 11.329 16.3897 11.4697 16.5303C11.6103 16.671 11.8011 16.75 12 16.75ZM16 19.25H12C11.8011 19.25 11.6103 19.329 11.4697 19.4697C11.329 19.6103 11.25 19.8011 11.25 20C11.25 20.1989 11.329 20.3897 11.4697 20.5303C11.6103 20.671 11.8011 20.75 12 20.75H16C16.1989 20.75 16.3897 20.671 16.5303 20.5303C16.671 20.3897 16.75 20.1989 16.75 20C16.75 19.8011 16.671 19.6103 16.5303 19.4697C16.3897 19.329 16.1989 19.25 16 19.25ZM27.75 6V19.5863C27.7504 19.8161 27.7054 20.0438 27.6174 20.2562C27.5294 20.4686 27.4003 20.6615 27.2375 20.8237L20.8237 27.2375C20.6615 27.4003 20.4686 27.5294 20.2562 27.6174C20.0438 27.7054 19.8161 27.7504 19.5863 27.75H6C5.53587 27.75 5.09075 27.5656 4.76256 27.2374C4.43437 26.9092 4.25 26.4641 4.25 26V6C4.25 5.53587 4.43437 5.09075 4.76256 4.76256C5.09075 4.43437 5.53587 4.25 6 4.25H26C26.4641 4.25 26.9092 4.43437 27.2374 4.76256C27.5656 5.09075 27.75 5.53587 27.75 6ZM6 26.25H19.25V20C19.25 19.8011 19.329 19.6103 19.4697 19.4697C19.6103 19.329 19.8011 19.25 20 19.25H26.25V6C26.25 5.9337 26.2237 5.87011 26.1768 5.82322C26.1299 5.77634 26.0663 5.75 26 5.75H6C5.9337 5.75 5.87011 5.77634 5.82322 5.82322C5.77634 5.87011 5.75 5.9337 5.75 6V26C5.75 26.0663 5.77634 26.1299 5.82322 26.1768C5.87011 26.2237 5.9337 26.25 6 26.25ZM25.19 20.75H20.75V25.19L25.19 20.75Z"
        fill="#6880CF"
      />
    </svg>
  );
};

export default UserMannual;
