import React from "react";
import ContactUsBanner from "../Components/ContactUsBanner";
import ContactWrapper from "../Components/ContactWrapper";

const ContactUs = () => {
  return (
    <>
      <ContactUsBanner />
      <ContactWrapper />
    </>
  );
};

export default ContactUs;
