import React from "react";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import phone from "../../../../assets/images/phone.png";
import wallpaper from "../../../../assets/images/wallpaper.png";
import { FeatureCard } from "../../../../global/components/FeatureCard/FeatureCard";

export const MobileLinkContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="custom-padding my-16">
        <FeatureCard
          image={phone}
          label="Mobile Link"
          description="Todays customers want to manage their systems via a mobile device. Mobile Manager provide secure access to the users system from any where. Our panel and mobile interconnect using SSL to a cloud app server where your smart device connect and interlink."
          imageLeft={true}
        />
        <FeatureCard
          image={wallpaper}
          //   label=""
          description="The App is capable of managing multiple sites, viewing of the system logs, video event tags, Door management such as lock and unlock with live view of video, User management allows the administrator the ability to add and edit users and credentials in the system. Threat level management allows the user to provide lock down management or cause event actions that allow the administrator to manage their facilities. Mobile App access and capabilities are based on user role permissions. A user on mobile is the same as a user logged into the system, audit reports keeps tracks of all modifications by user. Simple and Secure Mobility!"
          imageLeft={false}
        />
      </div>
    </>
  );
};
