import React from "react";

const PrivacyInfo = () => {
  return (
    <div className="custom-padding mt-[30px]">
      <h2 className="text-[#121212] text-[24px] font-bold mb-[20px]">
        Privacy Policy
      </h2>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Sicunet Website Privacy Policy <br /> Last modified: Jun 26, 2019
      </p>
      <p className="text-[20px] font-bold text-[#121212]">Introduction</p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Sicunet INC (“Sicunet”, “we”, or “us”) are committed to protecting your
        privacy it through our compliance with this policy. This policy
        describes the types of information we may collect from you or that you
        may provide when you use our mobile applications (collectively “Apps”)
        or visit our website https://www.sicunet.com/ (the “Websites”, and
        collectively with the Apps, “Apps/Websites”). This policy applies to
        information we collect:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify">
        On this Website. <br /> Our Affiliates Websites. <br />
        Through our Apps. <br /> In e-mail, text and other electronic messages.{" "}
        <br /> This policy only applies to information collected by us online
        and not by other offline methods of data collection not expressly stated
        in this policy. Please read this policy carefully. If you do not agree
        with our policies and practices, you should not access or use our
        Apps/Websites. By accessing or using Apps/Websites, you agree to this
        privacy policy. This policy may change from time to time (see Changes to
        our Privacy Policy below). If you have questions or concerns, please
        contact
      </p>
      <p className="text-[16px] text-[#2D2D32] font-semibold mb-[20px]">
        tech@sicunet.com.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Children Under the Age of 16
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify">
        Our Websites are not intended for children under the age of 16 years
        old. Any one under the age of 16 should not provide any information to
        or on Apps/Websites. We do not have knowledge of any users and
        associated data being under 16. If you are under 16, do not use or
        provide any information on or through the Apps/Websites or on or through
        any of their features, or provide any information about yourself. If we
        learn we have collected or received personal information from a child
        under 16 without confirmed parental consent, will be deleted. If you
        believe we might have any information from or about a child under 16,
        please contact us at
      </p>
      <p className="text-[16px] text-[#2D2D32] font-semibold mb-[20px]">
        tech@sicunet.com.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Information We Collect About You and How We Collect It
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        It is possible we may collect several types of information from and
        about users of our Apps/Websites, these may include the following:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        information you may be personally identified as, name, postal address,
        e-mail address, phone number, IP addresses, pictures, videos, or any
        other identifier by which you may be identified online or offline
        (“personal information”)that is about you; and/or
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        about the equipment, connection and devices you use to access our
        Apps/Websites and usage details.
      </p>
      <p className="text-[#4A4B53] text-[20px] font-medium text-justify mb-[20px]">
        This information could be collected by:
      </p>

      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        direct contact from you when you provide it to us; <br /> automatically
        as you navigate through our various portals and Websites; <br /> through
        our Apps; <br /> through cookies, web beacons and other tracking
        technologies; and/or <br /> from third parties, for example, our service
        providers or our business partners.
      </p>

      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Data You Provide to Us
      </p>
      <p className="text-[#4A4B53] text-[18px] font-medium text-justify mb-[20px]">
        The information that may be collected via our Apps/ Websites could
        include:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Information provided by on-line forms. Such as information required when
        subscribing to one of our services, posting collateral or request for
        additional services. Addresses, names, account type; residential,
        commercial; sales leads, contact information for third parties that may
        include individuals’ names, phone numbers, addresses, email addresses,
        which is possible to contain some personal information. <br /> In some
        cases we may also ask you for information when you report a problem with
        our Apps/Websites.Records and copies of your communications such as
        voice recording on phone, email address when you contact us.Your
        responses to surveys that we might ask you to complete for research
        purposes.You may be requested to provide financial information in
        connection with sales leads through our Apps/Websites or service partner
        websites.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Automated Data Collection may provide information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        When you navigate and utilize our Apps/Websites, we may use automatic
        data collection technologies to garner information about but not limited
        too, your devices, data, equipment, browsing actions and patterns,
        including:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Details of your visits data usage, location, logs and other
        communication data and resources used on the Apps/Websites;Information
        about the technology devices and methods used to connect, including your
        IP address, operating system and browser type; and Information about the
        status of connected devices to our Apps/Websites.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        We may use technologies to collect information about online activities
        across third-party websites or other online services (behavioral data).
        To opt out of behavioral tracking on the Apps/Websites and how we
        respond to web browser signals and other mechanisms that enable
        consumers to exercise choice about behavioral tracking see information
        below.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The automatic information we collect includes various information that
        is stored to link to other data we may obtain and associate with from
        third parties. The data and associated linkage helps us to improve our
        Apps/Websites, delivering improved and more personalized service,
        including by enabling us to:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Features and frequency of use; <br />
        System size and usage patterns; <br />
        Preferences, individual interests;
        <br />
        Enhanced speed of usage;
        <br />
        and Identification upon your return to our Apps/Websites.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The technologies we use for this automatic data collection may include:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Cookies (or browser cookies); Flash Cookies; Web Beacons.; We may tie
        this information to personal information about you that we collect from
        other sources or you provide to us. TIn many cases the user can turn off
        the capability to use such technologies. See below for more information.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Do Not Track. As referred to above, we may track Apps/Websites usage
        information through the use of cookies, pixels and other tracking
        capabilities, you have the option to enable of the “Do Not Track” option
        that is available in most browsers. Note that in some browser this
        feature may not have any effect on our collection of cookie information.
        The only way to completely “opt out” of the collection of any
        information through various technology is to actively manage the
        settings on your browser to delete and disable cookies and other
        tracking/recording tools. We caution you that, getting a “Do Not Track”
        signal to work may be difficult as not all data collection technology is
        performed through a browser. Even if you disable a tracking technology;
        not all settings will necessarily last or be effective; even if a
        setting is effective for one purpose data still may be collected for
        another. we observe the do not track requests.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Third-party Use
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Data collected on the Apps/Websites may be served by third-parties.
        These third parties may use cookies alone or in conjunction with other
        tracking technologies to collect information about you when you use the
        Apps/Websites. The information they collect may be associated with your
        personal information we have collected, regarding your online activities
        over time and across different websites and other online capabilities.
        This provides them with the capability to provide you with
        interest-based or other targeted content.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        We do not control these third parties’ tracking technologies or how they
        may be used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
        For information about how you can opt out of receiving targeted
        advertising from many providers, see Choices About How We Use and
        Disclose Your Information.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        How We Use Your Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The information we collect is used for the following; Provide
        information, products or services that you requested; <br />
        To full fill our obligations and protect our rights arising from any
        contracts entered into between you and us, including for billing and
        collection;
        <br />
        To inform you about changes to our Apps/Websites or any products or
        services;
        <br />
        To provide enhanced interactive capabilities via our on-line resources.;
        <br />
        For any other purpose with your consent. <br /> this information may
        also be used to communicate to you about other third-parties’ goods and
        services that may be of interest to you. If you do not want us to use
        your information in this way, please check the relevant box located on
        the form on which we collect your data (the registration form). For more
        information, see Choices About How We Use and Disclose Your Information.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Disclosure of Your Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        We may share information about our users, and other data collected,
        without restriction. <br /> This information will only be disclosed as
        described in this privacy policy: <br /> OEM partners and other third
        parties we use to support our business; <br /> To a buyer or other
        successor in the event of a merger, divestiture, restructuring,
        reorganization, dissolution or other sale or transfer of some or all of
        Sicunet’s assets. <br />
        To third parties to market their products or services to you
      </p>
      <p className="text-[#4A4B53] text-[18px] font-medium text-justify mb-[20px]">
        We may also disclose your information:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        To comply with any legal requirement or government or regulatory
        request;
        <br /> To enable enforcement of contractual agreements, such as billing
        and collection purposes; <br />
        and If it is necessary or appropriate to protect the rights, property,
        or safety of Sicunet, our customers or others;
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Choices About How We Use and Disclose Your Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        You have the option to set your browser to refuse all or some browser
        cookies, or to alert you when cookies are being sent. Sicunet is not
        responsible for the training and validation that the settings are
        correctly configured. There are on-line tools to learn how you can
        manage your Flash cookie setting. When usage / tracking technology is
        turned off, please note that some parts of the Apps/Websites may be
        inaccessible or not functioning correctly.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Legal use for Personal Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Sicunet may use information for legal consent:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Performance with Consent and of a Contract– It may be necessary that we
        process your personal data in order to validate consent to an
        agreement.Legitimate Interests –We use your personal information to
        develop and improve our portals, products and/or services for the
        benefit of our customers.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Personal Information Rights
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Your personal information collected and stored can be managed by you.
        Pursuant to local law, you may:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Request a copy of your personal information <br />
        Ask to update or change to make accurate; <br />
        Request the data be erased, blocked, or limited to certain use;
        <br /> Ask for us to notify and or transfer your personal information to
        a third party.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Accessing and Correcting Your Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Your personal information is housed and managed company maintain
        facilities. <br /> Your personal information is secured by our
        organizational and industry standards to protect your personal
        information and help prevent information from loss, theft, misuse and
        unauthorized access, disclosure, alteration and destruction. We are
        committed to ensuring that our third-party data center vendors provide
        adequate security measures. <br /> We retain you data as long as we deem
        the information is able to enhance our products and services and comply
        with all regulatory, legal requirements. <br /> We value you and your
        information. If at any time you have an issue or concern please contact
        us. However if you believe we have acted unsatisfactorily, you may have
        the right to submit a complaint to the data protection authority in your
        country or the relevant supervisory authority.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Your California Privacy Rights
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        California Civil Code Section 1798.83 permits California residents to
        request and obtain from us once a year, free of charge, a list of the
        third parties to whom we have disclosed personal information (as defined
        under that statute) of that California resident, for direct marketing
        purposes in the preceding calendar year and the categories of personal
        information that was disclosed to them. If you are a California resident
        and you wish to make such a request, you may contact us at
        tech@sicunet.com, or write to us at Sicunet located at: 4840 Irvine
        Blvd. Suite 113, Irvine, CA 9262​0.
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        We may provide you the ability to post information on or through the
        Apps/Websites. If you are a California resident who is under 18 and a
        registered user of the Apps/Website and you are unable to remove content
        or information you posted in a public area of the Apps/Websites by
        following the instructions provided on the Apps/Websites, you may
        request removal by contacting us at tech@sicunet.com. REMOVAL OF YOUR
        POSTED CONTENT OR INFORMATION FROM THE APPS/WEBSITES DOES NOT ENSURE
        COMPLETE OR COMPREHENSIVE REMOVAL OF THAT CONTENT OR INFORMATION FROM
        OUR SYSTEMS OR THE SYSTEMS OF OUR SERVICE PROVIDERS. Obligations under
        California law are satisfied as long we provide a redacted version of
        the content or information visible to other users and the public.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Data Security
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        Security of your data is important to us and we have taken precautions
        to ensure this information is not improperly disclosed. Unfortunately,
        the communication of information via the internet is not completely
        secure. Sicunet cannot guarantee the security of your personal
        information. Any transmission of personal information is at your own
        risk.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Privacy Policy Changes and Revision Control
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        The policy has a date of the revision at the top of the policy. It is
        your responsibility to periodiacally view and review for any changes. It
        is your responsibility to make sure we have an up-to-date email address
        for notification changes. If there are major changes to the policy we
        will update the on-line policy prior to it taking effect or send you a
        direct notification.
      </p>
      <p className="text-[20px] font-bold text-[#121212] mb-[20px]">
        Contact Information
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at:
      </p>
      <p className="text-[#4A4B53] text-[16px] font-medium text-justify mb-[20px]">
        tech@sicunet.com <br /> 4860 Irvine Blvd. Suite 207, <br /> Irvine, CA
        9262​0
      </p>
    </div>
  );
};

export default PrivacyInfo;
