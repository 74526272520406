import React from "react";
import RequestUser from "./RequestUser";
import RequestUserForm from "./RequestUserForm";

const RequestWrapper = () => {
  return (
    <div className="flex flex-col-reverse custom-padding lg:grid lg:grid-cols-2 pt-14 lg:pt-[150px] bg-[#0D0D0D] pb-6 md:pb-20">
      <RequestUser />
      <RequestUserForm />
    </div>
  );
};

export default RequestWrapper;
