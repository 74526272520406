import React from "react";
import "../Style/nvr.css";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import NvrOne from "../../../../assets/images/nvr-one.png";
import NvrTwo from "../../../../assets/images/nvr-two.png";
import NvrThree from "../../../../assets/images/nvr-three.png";
import NvrFour from "../../../../assets/images/nvr-four.png";

export const NvrContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="py-16 custom-padding">
        <p className="text-[24px] font-bold mb-8">
          LIVE VIEW OF NVR AND ASSOCIATED CAMERA’S - 8, 16 CAMERA MATRIX VIEW
        </p>
        <div className="mb-8">
          <img src={NvrOne} alt="image" />
        </div>
        <div className="mb-8">
          <img src={NvrTwo} alt="image" />
        </div>
        <div className="mb-8">
          <img src={NvrThree} alt="image" />
        </div>
        <p className="text-[24px] font-bold mb-8">VIEW A LIVE CAMERA FEED</p>
        <div className="mb-8">
          <img src={NvrFour} alt="image" />
        </div>
      </div>
    </>
  );
};
