import React from "react";

const Flyer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M24.6665 8H25.3332C27.2185 8 28.1612 8 28.7465 8.58667C29.3332 9.17067 29.3332 10.1133 29.3332 12V21.3333C29.3332 23.2187 29.3332 24.1613 28.7465 24.7467C28.1612 25.3333 27.2185 25.3333 25.3332 25.3333H24.6665M7.33317 8H6.6665C4.78117 8 3.8385 8 3.25317 8.58667C2.6665 9.17067 2.6665 10.1133 2.6665 12V21.3333C2.6665 23.2187 2.6665 24.1613 3.25317 24.7467C3.83717 25.3333 4.77984 25.3333 6.6665 25.3333H7.33317M19.3332 10.6667H12.6665M19.3332 16H12.6665M19.3332 21.3333H12.6665M24.6665 22.6667V9.33333C24.6665 6.81867 24.6665 5.56267 23.8852 4.78133C23.1038 4 21.8478 4 19.3332 4H12.6665C10.1518 4 8.89584 4 8.1145 4.78133C7.33317 5.56267 7.33317 6.81867 7.33317 9.33333V22.6667C7.33317 25.1813 7.33317 26.4373 8.1145 27.2187C8.89584 28 10.1518 28 12.6665 28H19.3332C21.8478 28 23.1038 28 23.8852 27.2187C24.6665 26.4373 24.6665 25.1813 24.6665 22.6667Z"
        stroke="#6880CF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Flyer;
