import React from "react";
import "../Style/contactUs.css";
const ContactUsBanner = () => {
  return (
    <div className=" flex justify-center items-center text-white z-50 contact-banner">
      <h1 className=" text-white text-[24px] md:text-[48px] lg:text-[78px] font-semibold leading-[97px]">
        Contact Us
      </h1>
    </div>
  );
};

export default ContactUsBanner;
