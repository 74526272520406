import React from "react";
import { FeatureCard } from "../../../../global/components/FeatureCard/FeatureCard";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import bgTwo from "../../../../assets/images/about-one.png";
import locksetBg from "../../../../assets/images/locksetBg.png";

const LocksetContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="py-16 custom-padding">
        <FeatureCard
          image={locksetBg}
          label="SCHLAGE LOCKSET"
          description="Integrated wireless locks by Schlage NDE. Add a TCIP connect Wireless BLE bridge and you can add up to 10 NDE Locksets. The bridge and locksets communicate via BLE for a wake up and respond of actions and transfer of transactions. The system allows for real time knowledge of door status and lock down. Adding NDE locks set provides a lower cost of installation for doors that may have fewr transactions and level of security. The NDE design packages the cylindrical lock, credential reader, and access control sensors together in to a small footprint that is both easy to install and affordable. NDE enables the use of both proximity (125 kHz) and/or smart (13.56 MHz) credentials which feature a much higher level of security and versatility."
          imageLeft={true}
        />
        <div className="flex flex-col justify-center text-center  my-20">
          <p className="text-2xl font-semibold pb-8">Connectivity</p>
          <p className="font-light">
            Wi-Fi access point (automatic daily updates) ENGAGE™ Gateway allows
            real time communication via IP to server.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center mb-8">
          <div className="hidden lg:block w-full lg:w-1/2 lg:mr-16">
            <div className="flex flex-col justify-center mb-8">
              <p className="text-2xl font-semibold pb-8">
                Communication standards
              </p>
              <p className="font-light">
                2.4 GHz Wi-Fi (IEEE 802.11b/g) WPA2, WPA, WEP, 802.1x Bluetooth
                low energy (version 4.0) Transport Layer Security (TLS) version
                1.0 Advanced Encryption Standard (AES), 256-bit Wake-Up on Radio
                Responds to command from host in less than 5 seconds
              </p>
            </div>
            <div className="flex flex-col justify-center mb-12 lg:mb-0">
              <p className="text-2xl font-semibold pb-8">Additional</p>
              <p className="font-light">
                Gateway communication range – up to 30 feetData rate – up to 54
                Mbps via Wi-FiBattery life – up to 2 years with 4 AA
                batteriesAll system data is still maintained at theEdge – System
                integrity is strong. Customer owns the system and
                dataFoundational Layer for Services Platform Modules
              </p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
            <img src={bgTwo} alt="Image" className="object-cover w-full" />
          </div>
          <div className="lg:hidden w-full lg:w-1/2 lg:mr-16">
            <div className="flex flex-col justify-center mb-8">
              <p className="text-2xl font-semibold pb-8">
                Communication standards
              </p>
              <p className="font-light">
                2.4 GHz Wi-Fi (IEEE 802.11b/g) WPA2, WPA, WEP, 802.1x Bluetooth
                low energy (version 4.0) Transport Layer Security (TLS) version
                1.0 Advanced Encryption Standard (AES), 256-bit Wake-Up on Radio
                Responds to command from host in less than 5 seconds
              </p>
            </div>
            <div className="flex flex-col justify-center mb-12 lg:mb-0">
              <p className="text-2xl font-semibold pb-8">Additional</p>
              <p className="font-light">
                Gateway communication range – up to 30 feetData rate – up to 54
                Mbps via Wi-FiBattery life – up to 2 years with 4 AA
                batteriesAll system data is still maintained at theEdge – System
                integrity is strong. Customer owns the system and
                dataFoundational Layer for Services Platform Modules
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocksetContent;
