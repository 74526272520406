import React from "react";
import { FeatureTopBar } from "../../FeatureTopBar/Component/FeatureTopBar";
import { FeatureCard } from "../../../../global/components/FeatureCard/FeatureCard";
import ReportOne from "../../../../assets/images/report-one.png";
import ReportTwo from "../../../../assets/images/report-two.png";
import ReportThree from "../../../../assets/images/report-three.png";

export const SmartReportContent = () => {
  return (
    <>
      <FeatureTopBar />
      <div className="my-16 custom-padding">
        <FeatureCard
          image={ReportOne}
          label="Smart Reports"
          description="At the heart of every great access control system is its ability to provide quick and easy reports. Professional Plus feature set offers an integrated advanced report creation function."
          imageLeft={true}
        />
        <FeatureCard
          image={ReportTwo}
          //   label="SCHLAGE LOCKSET"
          description="No third party software to load or run. Our intuitive ”Smart Report” wizard steps the administrator through the creation of a report that customizes the input data and output of the report they would like to generate."
          imageLeft={false}
        />
        <FeatureCard
          image={ReportThree}
          //   label="SCHLAGE LOCKSET"
          description="Click through the set up. The Administrator to create by name and can even assign the report so only certain users can run. If you require other uses for the data our system allows the administrator to export the data as Text, CSV and HTML file. Customers that would like to generate a report in a specific way and lay it out they way that best meet their requirements – Smart Reports is your solution.
Create the report and have it provide you the direct data you require."
          imageLeft={true}
        />
      </div>
    </>
  );
};
