import React, { useEffect, useState } from "react";
import "../Style/home.css";
import { Button } from "antd";

export const HomeContent = () => {
  return (
    <div className="home-container flex flex-col pt-36 md:pt-[250px] custom-padding">
      <div className="text-animation">
        <p className="header-text">
          Don’t Let Your Access Control{" "}
          <span className="hidden md:inline">
            <br />
          </span>
          System Compromise Your Facility’s{" "}
          <span className="hidden md:inline">
            <br />
          </span>
          Security
        </p>
      </div>

      <div className="pt-8 text-animation">
        <p className="body-text">
          Experience seamless data control with our hybrid system, combining the
          flexibility of cloud technology with
          <span className="hidden md:inline">
            <br />
          </span>
          the security and ease of on-premise management. Our solution ensures
          you always own your data,
          <span className="hidden md:inline">
            <br />
          </span>
          delivering the best of both worlds without compromise.
        </p>
      </div>

      <div className="pt-8 text-animation">
        <Button
          type="primary"
          className="rounded-md py-[20px] px-[16px] text-[17px]"
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};
