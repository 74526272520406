import React from "react";
import ProductCard from "./ProductCard";
import product1 from "../../../assets/product/product1.png";
import product2 from "../../../assets/product/product2.png";
import product3 from "../../../assets/product/product3.png";
import product4 from "../../../assets/product/product4.png";
import product5 from "../../../assets/product/product5.png";
import product6 from "../../../assets/product/product6.png";
import product7 from "../../../assets/product/product7.png";
import product8 from "../../../assets/product/product8.png";
import product9 from "../../../assets/product/product9.png";
import product10 from "../../../assets/product/product10.png";
import product11 from "../../../assets/product/product11.png";
import product12 from "../../../assets/product/product12.png";
const ProductCardWrapper = () => {
  const cardData = [
    {
      id: 1,
      title: "PATAGONIA PROXIMITY READER & KEYPAD",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product1,
    },
    {
      id: 2,
      title: "DENALI MULLION-MOUNT READER & KEYPAD",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product2,
    },
    {
      id: 3,
      title: "CASCADE PROXIMITY READER P-500",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product3,
    },
    {
      id: 4,
      title: "CASCADE PROXIMITY READER P-300",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product4,
    },
    {
      id: 5,
      title: "MI-RD-M",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product5,
    },
    {
      id: 6,
      title: "ET 10/10L",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product6,
    },
    {
      id: 7,
      title: "ET 5",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product7,
    },
    {
      id: 8,
      title: "ET 25L",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product8,
    },
    {
      id: 9,
      title: "ET 20L",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product9,
    },
    {
      id: 10,
      title: "125KHZ CREDENTIALS",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product10,
    },
    {
      id: 11,
      title: "13.56MHZ CREDENTIALS",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product11,
    },
    {
      id: 12,
      title: "RMC-1S ",
      info: "Pyramid Series Proximity® from Farpointe Data sets",
      offerPrice: "600.00",
      actualPrice: "750.00",
      image: product12,
    },
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-[24px] gap-x-[15px] mt-6 lg:mt-20">
      {cardData.map((item) => (
        <ProductCard
          key={item?.id}
          title={item.title}
          info={item.info}
          offer={item.offerPrice}
          actual={item.actualPrice}
          img={item.image}
          id={item.id}
        />
      ))}
    </div>
  );
};

export default ProductCardWrapper;
