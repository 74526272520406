import React from "react";

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.3316 3.67008C20.1424 3.48225 19.9051 3.3502 19.6458 3.28845C19.3864 3.22671 19.1151 3.23765 18.8616 3.32008L4.23159 8.20008C3.96026 8.28606 3.72049 8.45051 3.54258 8.67267C3.36466 8.89484 3.25657 9.16474 3.23196 9.4483C3.20735 9.73186 3.26732 10.0164 3.4043 10.2658C3.54128 10.5153 3.74913 10.7186 4.00159 10.8501L10.0716 13.8501L13.0716 19.9401C13.1922 20.1785 13.3767 20.3786 13.6045 20.5181C13.8323 20.6576 14.0944 20.731 14.3616 20.7301H14.4616C14.7477 20.709 15.0208 20.6024 15.2455 20.424C15.4702 20.2457 15.6361 20.0039 15.7216 19.7301L20.6716 5.14008C20.76 4.88801 20.7749 4.61602 20.7148 4.35578C20.6546 4.09553 20.5217 3.85774 20.3316 3.67008ZM4.85159 9.58008L17.6216 5.32008L10.5316 12.4101L4.85159 9.58008ZM14.4316 19.1501L11.5916 13.4701L18.6816 6.38008L14.4316 19.1501Z"
        fill="#FEFFFF"
      />
    </svg>
  );
};

export default SendIcon;
