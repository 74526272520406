import React from "react";
import Quantity from "./Quantity";
import AlwaysHelp from "./AlwaysHelp";
const PurchaseProduct = () => {
  return (
    <div className="flex flex-col gap-5 items-center">
      <Quantity />
      <AlwaysHelp />
    </div>
  );
};

export default PurchaseProduct;
