import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";

const PageLayout = ({ children }) => {
  return (
    <div>
      <NavBar />
      <div className="">{children}</div>
      <Footer />
    </div>
  );
};

export default PageLayout;
