import React from "react";
export const Facebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FEFFFF"
      d="M13.3 13.847h2.25l.9-3.6H13.3v-1.8c0-.927 0-1.8 1.8-1.8h1.35V3.623a25.35 25.35 0 0 0-2.571-.126c-2.444 0-4.179 1.491-4.179 4.23v2.52H7v3.6h2.7v7.65h3.6v-7.65Z"
    />
  </svg>
);
