import React from "react";

const WarrantyPolicyBanner = () => {
  return (
    <div className="flex justify-center items-center text-white z-50 warranty-banner">
      <h1 className="text-white text-[24px] md:text-[48px] font-semibold leading-[97px]">
        Warranty Policy
      </h1>
    </div>
  );
};

export default WarrantyPolicyBanner;
